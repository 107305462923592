import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import handGood from "../../assets/hand-good.png";
import handWrong from "../../assets/hand-wrong.png";
import hand from "../../assets/mano.png";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateScore, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import { modalGeneric, modalGenericSmall } from "../../utils/logger";

const QUIZ_ID = QUIZZES.SCENARIO5_3;
const SCENARIO_ID = SCENARIOS.SCENARIO_5;
const NAVIGATE_TO = ROUTES.SCENARIO5_PART2_END;

const CORRECT_ANSWER_POINTS = 10;
const FIRST_ATTEMPT_EXTRA_POINTS = 20;
const WRONG_ANSWER_POINTS = -10;

function Scenario5Part2Question2({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const participant = useSelector((state) => state.group);

	const scenarioNum = getScenarioByScreen(location.pathname);

	const [intentos, setIntentos] = useState(1);
	const [isCompleted, setIsCompleted] = useState(false);

	const [points, setPoints] = useState({ score: 0, bonus: 0, penalty: 0 });
	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "Voclosporina es el primer fármaco oral aprobado específicamente para el tratamiento de la nefritis lúpica en Europa.",
			selected: false,
			correct: false,
		},
		{
			id: 2,
			text: "Voclosporina se administra a dosis fijas y no requiere monitorización de las concentraciones plasmáticas.",
			selected: null,
			correct: false,
		},
		{
			id: 3,
			text: "Voclosporina se puede administrar de forma concomitante con el ácido micofenólico.",
			selected: false,
			correct: false,
		},
		{
			id: 4,
			text: "Voclosporina tiene un efecto favorable sobre el metabolismo de los lípidos y los electrolitos, y negativo sobre el metabolismo de la glucosa.",
			selected: false,
			correct: true,
		},
		{
			id: 5,
			text: "El perfil de seguridad de voclosporina es comparable al del tratamiento estándar.",
			selected: false,
			correct: false,
		},
	]);

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		function handleOptionClick() {
			if (isCompleted) return;

			let updatedOptions = options.map((x) =>
				x.id === id
					? { ...x, selected: !x?.selected }
					: { ...x, selected: false }
			);

			setOptions(updatedOptions);
		}

		function getBackgroundColor() {
			return option?.selected && option?.correct && isCompleted
				? "#D6D431"
				: option?.selected && !option?.correct && isCompleted
				? "#FF5948"
				: option?.selected
				? "#EAF886"
				: "#FFFFFF";
		}

		return (
			<div>
				<div
					className={`d-flex align-items-center justify-content-start txt-dark-green p-3 mb-3 ${
						option?.selected ? "option-selected bold" : ""
					}`}
					onClick={handleOptionClick}
					style={{
						position: "relative",
						minHeight: "10vh",
						height: "fit-content",
						borderRadius: "10px",
						lineHeight: "20px",
						backgroundColor: getBackgroundColor(),
					}}
				>
					<div className="d-flex align-items-start">
						<div>{option?.id}.</div>
						<div className="ms-2">{option?.text}</div>
					</div>
				</div>
			</div>
		);
	};

	function checkAnswers() {
		const correct = options?.find((x) => x.selected && x.correct);

		const score = correct
			? CORRECT_ANSWER_POINTS +
			  (intentos === 1 ? FIRST_ATTEMPT_EXTRA_POINTS : 0)
			: WRONG_ANSWER_POINTS;

		setPoints({
			...points,
			score: points.score + score,
			bonus: correct && intentos === 1 ? FIRST_ATTEMPT_EXTRA_POINTS : 0,
			penalty: correct
				? points.penalty
				: points.penalty + WRONG_ANSWER_POINTS,
		});

		if (correct) {
			const text = `<div class="txt14">
					<div class="txt-turquoise bold txt18 text-center">
						¡Efectivamente, esta es la afirmación infiltrada!
					</div>
					<div class="d-flex justify-content-center my-3 text-center">
						<div class="bg-correct txt-black w-fit-content p-2 bold">
							<img width="25" src=${handGood} class="me-1" />
							+${score} puntos
						</div>
					</div>
					<div>En su estudio pivotal AURORA 1<sup>1</sup>:</div>
					<div>
						· Voclosporina demostró tener un <span class="bold">efecto favorable</span> sobre
						el <span class="bold">metabolismo lipídico</span>, reduciendo de forma
						estadísticamente significativa el colesterol total y las LDL.
					</div>
					<div>
						· Voclosporina <span class="bold">no tuvo impacto</span> en la <span class="bold">hemoglobina glicosilada</span> ni en
						las <span class="bold">concentraciones de glucosa sérica</span>, que se
						mantuvieron estables a lo largo de todo el estudio.
					</div>
					<div>
						· Solo se observó <span class="bold">diabetes de novo</span> en un paciente del <span class="bold">grupo placebo</span>.
					</div>
					<div>
						· Los <span class="bold">electrolitos</span> y las <span class="bold">concentraciones medias de
						magnesio y potasio</span> se mantuvieron <span class="bold">estables</span> para ambos
						brazos de tratamiento.
					</div>
					<div class="txt8 mt-3">
						<sup>1</sup>Rovin BH, Onno Teng YK, Ginzler EM, et al. Efficacy and
						safety of voclosporin versus placebo for lupus nephritis
						(AURORA 1): a double-blind, randomised, multicentre,
						placebo-controlled, phase 3 trial. Lancet.
						2021;397:2070-80.
					</div>
				</div>`;

			modalGeneric({ html: text });
			setIsCompleted(true);
		} else {
			const text = `<div class="text-center txt14">
					<div class="txt-turquoise bold txt18">
						Esta no es la afirmación infiltrada.
					</div>
					<div class="d-flex justify-content-center my-3">
						<div class="bg-incorrect txt-black w-fit-content p-2">
							<img width="20" src=${handWrong} class="me-1" />
							${score} puntos
						</div>
					</div>
					<div>Inténtalo de nuevo.</div>					
				</div>`;

			modalGenericSmall({ html: text, allowOutsideClick: true });
		}

		setIntentos(intentos + 1);
	}

	async function submitAnswer() {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: participant?._id,
			score: points.score,
			time: time,
			correct: true,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateScore(points.score, SCORE_TYPES.SCORE));
					dispatch(updateScore(points.bonus, SCORE_TYPES.BONUS));
					dispatch(updateScore(points.penalty, SCORE_TYPES.PENALTY));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + points.score,
						bonus: participant?.bonus + points.bonus,
						penaltyScore:
							participant?.penaltyScore + points.penalty,
						lastVisited: NAVIGATE_TO,
					});
					navigate(NAVIGATE_TO, {
						state: { activeScenario: scenarioNum + 1 },
					});
				}
			})
			.catch((error) => {});
	}

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					<div className="d-flex justify-content-start align-items-center mb-1">
						<img className="me-2" src={hand} />
						<div className="txt-white">
							Indica cuál es la “afirmación infiltrada”. Tienes
							tantos intentos como sea preciso.
						</div>
					</div>
					<div className="row d-flex justify-content-center align-items-start">
						<div className="col-4">
							Rovin BH, Onno Teng YK, Ginzler EM, et al. Efficacy
							and safety of voclosporin versus placebo for lupus
							nephritis (AURORA 1): a double-blind, randomised,
							multicentre, placebo-controlled, phase 3 trial.
							Lancet. 2021;397:2070-80.
						</div>
						<div className="col-8">
							{options.map((option) => {
								return (
									<div>
										<SelectOption id={option.id} />
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					right: "0",
					bottom: "13vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					<div
						className="btn-main"
						onClick={() => {
							if (isCompleted) submitAnswer();
							else checkAnswers();
						}}
					>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario5Part2Question2);
