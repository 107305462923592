import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMedical from "../../assets/logo-medical.png";
import martina from "../../assets/martina.png";

import NextButton from "../../components/NextButton";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";

function Scenario4Intro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					style={{ paddingLeft: "6vh" }}
					className="row d-flex justify-content-start align-items-start mt-5 pt-5"
				>
					<div className="col-3 txtWhite">
						<div
							className="txtWhite txt20"
							style={{ letterSpacing: 3 }}
						>
							ESCENARIO 4
						</div>
						<div
							className="txtWhite txt30 bold mt-3"
							style={{ letterSpacing: 3, lineHeight: "30px" }}
						>
							Tratamiento
						</div>
					</div>
					<div className="col-9 d-flex align-items-center txt16 pe-5">
						<img src={martina} width={235} />
						<div className="txt-white ms-3">
							<div className="bold mb-3">Recuerda:</div>
							<ul>
								<li>Martina, 37 años.</li>
								<li>
									Lupus eritematoso sistémico sin
									manifestaciones extrarrenales actualmente,
									no tratamiento.
								</li>
								<li>
									Nefritis lúpica clase IV, filtrado
									glomerular conservado, proteinuria 3070
									mg/24 h, microhematuria y leucocituria.
								</li>
								<li>
									Anticuerpos contra el ADN de doble cadena +
									consumo de complemento (C3-C4).
								</li>
							</ul>
						</div>
					</div>
					<div
						className="container"
						style={{
							position: "absolute",
							right: "7vh",
							bottom: "7vh",
						}}
					>
						<div className="d-flex justify-content-end">
							<NextButton navigateTo={ROUTES.SCENARIO4} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario4Intro);
