import { combineReducers } from "redux";
import { SCORE_TYPES } from "../utils/constants";
import {
	SET_GAME,
	SET_GROUP,
	UPDATE_SCENARIO,
	UPDATE_SCORE,
	UPDATE_TIME,
} from "./actions";

const initialState = {
	game: null,
	group: null,
	scenario: null,
};

function game(state = initialState.game, action) {
	switch (action.type) {
		case SET_GAME:
			return action.game;
		default:
			return state;
	}
}

function group(state = initialState.group, action) {
	switch (action.type) {
		case SET_GROUP:
			return action.group;
		case UPDATE_TIME:
			let newState2 = {
				...state,
				time: action.time,
			};
			return newState2;
		case UPDATE_SCENARIO:
			let newState3 = {
				...state,
				activeScenario: action.activeScenario,
			};
			return newState3;
		case UPDATE_SCORE:
			let newScore =
				action.typeOfScore === SCORE_TYPES.SCORE
					? 0 || state.score + action.score
					: state.score;

			let newBonus =
				action.typeOfScore === SCORE_TYPES.BONUS
					? 0 || state.bonus + action.score
					: state.bonus;

			let newPenalty =
				action.typeOfScore === SCORE_TYPES.PENALTY
					? 0 || state.penalty + action.score
					: state.penalty;

			let newStateScore = {
				...state,
				score: newScore,
				bonus: newBonus,
				penalty: newPenalty,
			};

			return newStateScore;
		default:
			return state;
	}
}

export default combineReducers({ game, group });
