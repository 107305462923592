import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMedical from "../../assets/logo-medical.png";
import martina from "../../assets/martina.png";
import NextButton from "../../components/NextButton";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function Scenario2Result({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const scenarioNum = getScenarioByScreen(location.pathname);

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5 pt-5">
					<div className="col-9">
						<div className="ms-5 ps-4 d-flex align-items-center">
							<img width={350} src={martina} />
							<div className="ms-4 txtWhite txt20 text-center">
								<div className="txt18">
									Así pues, el diagnóstico de Martina es:
								</div>
								<div className="txt-yellow bold my-4">
									Nefritis lúpica clase IV Índice de
									actividad: 12/24; índice de cronicidad: 1/12
								</div>
								<div className="txt18">
									Ahora{" "}
									<span className="bold">
										hay que valorar un tratamiento para la
										paciente
									</span>
									. Pero antes…
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton
						navigateTo={ROUTES.TRANSITION_SCENARIOS}
						activeScenario={scenarioNum + 1}
					/>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario2Result);
