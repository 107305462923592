import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getDefaultGame, getGameById } from "../api/game";
import { postGroup } from "../api/group";
import logoMedical from "../assets/logo-medical.png";
import { setGame, setGroup } from "../redux/actions";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";
import { setGroupId, updateStats } from "../utils/helpers";
import { alertError } from "../utils/logger";

function getEmptyGroup() {
	return {
		fullName: "",
		game: null,
	};
}

function Register({ intl }) {
	const [activeGame, setActiveGame] = useState(null);
	const [activeGroup, setActiveGroup] = useState(getEmptyGroup());
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		const asyncFetch = async () => {
			if (prevState?.state?.game) {
				getGameById(prevState.state.game).then((res) => {
					if (res.status === 200) {
						handleData(res.data);
					}
				});
			} else {
				getDefaultGame().then((res) => {
					if (res.status === 200) {
						let defaultGame = res.data;
						handleData(defaultGame);
					}
				});
			}
		};

		asyncFetch();
	}, []);

	function handleData(game) {
		dispatch(setGame(game));
		setActiveGame(game);
		setActiveGroup((prevGroup) => ({
			...prevGroup,
			game: game._id,
		}));
	}

	function saveGroup(e) {
		e.preventDefault();
		if (activeGroup?.fullName) {
			postGroup(activeGroup)
				.then((res) => {
					if (res.status === 201) {
						let group = {
							...res.data,
							score: 0,
							time: 0,
							penalty: 0,
							bonus: 0,
						};
						setGroupId(group?._id);
						dispatch(setGame(group?.game));
						dispatch(setGroup(group));

						updateStats({
							...group,
							lastVisited: ROUTES.VIDEO,
						});
						navigate(ROUTES.VIDEO);
					}
				})
				.catch((error) => {
					alertError({
						error: " ",
						customMessage: error?.response?.data?.message || " ",
					});
				});
		} else
			alertError({
				error: " ",
				customMessage: "¡Escribe el nombre del equipo!",
			});
	}

	const handleChange = (element) => (event) => {
		setActiveGroup({ ...activeGroup, [element]: event.target.value });
	};

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row h-50 d-flex justify-content-center align-items-center">
					<div className="col-5">
						<div className="title1 mt-4 mb-1 txtWhite text-center">
							<span className="bold">
								{"Pon un nombre a tu equipo "}
							</span>
							<br />
							<div className="d-inline-flex txtWhite txt14">
								{"(máximo 15 caracteres)"}
							</div>
						</div>
						<input
							id={`fullName`}
							InputLabelProps={{
								shrink: true,
							}}
							autoComplete="off"
							maxLength={15}
							onChange={handleChange("fullName")}
							placeholder="Escribe el nombre aquí"
							required
							className="form-control bg-white txt-black mt-4"
						/>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<div
						className="btn-main"
						onClick={(e) => {
							saveGroup(e);
						}}
					>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Register);
