import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getGroupById } from "../api/group";
import logoMedicalWhite from "../assets/logo-medical-white.png";
import logoOtsuka from "../assets/logo-otsuka.svg";
import logoVivactis from "../assets/logo-vivactis.png";
import logoLupkynis from "../assets/lupkynis.png";
import triangulo from "../assets/triangulo.png";
import { ROUTES } from "../utils/constants";
import {
	clearGroup,
	clearTimer,
	getGroupId,
	reconnect,
} from "../utils/helpers";
import { alertResume } from "../utils/logger";

function Home({ intl }) {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const searchParams = new URLSearchParams(location.search);
	const gameId = searchParams.get("game");

	useEffect(() => {
		let participantId = getGroupId();
		if (participantId) {
			alertResume().then((res) => {
				if (res.isConfirmed) {
					getGroupById(participantId).then((res) => {
						if (res.status === 200)
							reconnect({
								group: res.data,
								dispatch: dispatch,
								navigate: navigate,
								navigateTo: res.data?.finished
									? ROUTES.END
									: null,
							});
					});
				} else {
					clearGroup();
					clearTimer();
				}
			});
		} else {
			clearTimer();
		}
	}, []);

	return (
		<div className="startbg txtWhite">
			<div className="d-flex justify-content-between px-5 pt-4 mt-3">
				<img src={logoOtsuka} width={150}></img>
				<img src={logoVivactis} width={150}></img>
			</div>
			<div className="row d-flex justify-content-end mt-5">
				<div className="col-4 d-flex justify-content-center">
					<div className="d-flex flex-column align-items-center">
						<img src={logoMedicalWhite} width={250}></img>
						<div
							className="btn-main mt-4"
							style={{ width: "186px" }}
							onClick={() => {
								navigate(ROUTES.REGISTER, {
									state: { game: gameId },
								});
							}}
						>
							<div className="text-btn-main">
								EMPEZAR EL JUEGO
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				className="legalGebro position-absolute"
				style={{ marginLeft: "70vw" }}
			>
				<div className="d-flex flex-column align-items-center justify-content-end">
					<img width={200} src={logoLupkynis}></img>
					<div className="d-flex justify-content-center align-items-start">
						<img className="mt-1" src={triangulo}></img>
						<div className="w-75">
							Este medicamento está sujeto a seguimiento
							adicional, es prioritaria la notificación de
							sospechas de reacciones adversas asociadas a este
							medicamento.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Home);
