import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import analitica3meses from "../../assets/analitica-3meses.png";
import logoMedical from "../../assets/logo-medical.png";

import NextButton from "../../components/NextButton";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";

function Scenario5Intro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					style={{ paddingLeft: "6vh" }}
					className="row d-flex justify-content-start align-items-start mt-5 pt-5"
				>
					<div className="col-4 txtWhite">
						<div
							className="txtWhite txt20"
							style={{ letterSpacing: 3 }}
						>
							ESCENARIO 5
						</div>
						<div
							className="txtWhite txt30 bold mt-3"
							style={{ letterSpacing: 3, lineHeight: "35px" }}
						>
							Actitud terapéutica
						</div>
						<div style={{ lineHeight: "20px" }}>
							<div className="bold mt-4">
								Tres meses más tarde, <br />
								Martina acude a tu consulta.
							</div>
							<div className="mt-3">
								Antes de que entre, revisas los resultados de
								sus analíticas. Obsérvalos con atención.
							</div>
							<div className="mt-3">
								Consumo C3 (mes 1 – mes 3): 12 mg/dl <br />
								Consumo C4 (mes 1 – mes 3): 2 mg/dl
							</div>
						</div>
					</div>
					<div className="col-8" style={{ marginTop: "-10vh" }}>
						<img src={analitica3meses} width={600} />
					</div>
					<div
						className="container"
						style={{
							position: "absolute",
							right: "7vh",
							bottom: "7vh",
						}}
					>
						<div className="d-flex justify-content-end">
							<NextButton
								navigateTo={ROUTES.SCENARIO5_CONVERSATION}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario5Intro);
