import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/arrow-left.png";
import arrowRight from "../../assets/arrow-right.png";
import buttonAmpliar from "../../assets/button-ampliar.png";
import cell1Selected from "../../assets/cell1-selected.png";
import cell2Selected from "../../assets/cell2-selected.png";
import cell3Selected from "../../assets/cell3-selected.png";
import cell4Selected from "../../assets/cell4-selected.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalImage } from "../../utils/logger";

function Scenario3Explanation({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const scenarioNum = getScenarioByScreen(location.pathname);

	const [options, setOptions] = useState([
		{
			id: 1,
			text: (
				<div>
					<span className="bold">Depósitos subendoteliales</span>{" "}
					(asas en alambre)
				</div>
			),
			image: cell1Selected,
		},
		{
			id: 2,
			text: (
				<div>
					<span className="bold">Depósitos subendoteliales</span>{" "}
					(trombos hialinos)
				</div>
			),
			image: cell2Selected,
		},
		{
			id: 3,
			text: <div>Semiluna celular 2 de 3</div>,
			image: cell3Selected,
		},
		{
			id: 4,
			text: <div>Inflamación intersticial</div>,
			image: cell4Selected,
		},
	]);

	const [activeImage, setActiveImage] = useState(options[0]?.image);

	const ImageCarousel = () => {
		const images = options?.map((x) => x.image);
		const activeIndex = images.findIndex((x) => x === activeImage);

		const nextImage = () => {
			const nextIndex = (activeIndex + 1) % images.length;
			setActiveImage(images[nextIndex]);
		};

		const prevImage = () => {
			const prevIndex = (activeIndex - 1 + images.length) % images.length;
			setActiveImage(images[prevIndex]);
		};

		return (
			<div className="d-flex align-items-center">
				<img onClick={prevImage} src={arrowLeft} />
				<div className="image-carousel-container d-flex justify-content-center w-100 mx-3">
					<img height={400} src={activeImage} />
					<div
						style={{
							position: "absolute",
							bottom: "5%",
							right: "5%",
						}}
					>
						<img
							onClick={() => modalImage({ image: activeImage })}
							width={70}
							src={buttonAmpliar}
						/>
					</div>
				</div>
				<img onClick={nextImage} src={arrowRight} />
			</div>
		);
	};

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					<div className="row d-flex justify-content-center align-items-center my-4">
						<div className="col-8 px-4">
							<ImageCarousel />
						</div>
						<div className="col-4">
							{options?.find((x) => x?.image === activeImage) && (
								<div className="txt-white txt20">
									{
										options?.find(
											(x) => x?.image === activeImage
										).text
									}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "13vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton navigateTo={ROUTES.SCENARIO3_RESULT} />
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario3Explanation);
