import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import handGood from "../../assets/hand-good.png";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateScore, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertError,
	modalGeneric,
	modalGenericSmall,
	modalQuestionInfo,
} from "../../utils/logger";

const QUESTION_EXPLANATION = `<div class="text-center txt-black">
		<div class="txt-turquoise bold">
			¿Qué recomiendan otras sociedades científicas acerca de la duración
			del tratamiento?
		</div>
		<div class="d-flex justify-content-center mt-4">
			<div class="bg-correct p-2 w-fit-content">
				<img width="25" src=${handGood} class="me-1" />
				<span class="bold">+10</span> puntos
			</div>
		</div>
		<div class="mt-4">
			Vamos a plantearte 2 enunciados que pueden ser Verdaderos o Falsos.
		</div>
		<div class="txt-turquoise bold mt-4">¿Preparado?</div>
	</div>`;

const QUIZ_ID = QUIZZES.SCENARIO7;
const SCENARIO_ID = SCENARIOS.SCENARIO_7;
const NAVIGATE_TO = ROUTES.SCENARIO7_QUESTION2;

const CORRECT_ANSWER_POINTS = 50;

function Scenario7({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const participant = useSelector((state) => state.group);

	const scenarioNum = getScenarioByScreen(location.pathname);

	const [intentos, setIntentos] = useState(1);
	const [isCompleted, setIsCompleted] = useState(false);

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "Análogos del ácido micofenólico (MPAA)",
			answers: [
				{
					id: 2,
					selected: false,
					correct: false,
					text: "Continuar como tratamiento de mantenimiento (1-3 años)",
				},
				{
					id: 3,
					selected: false,
					correct: false,
					text: "Continuar como tratamiento de mantenimiento (2-4 años)",
				},
				{
					id: 4,
					selected: false,
					correct: true,
					text: "Continuar como tratamiento de mantenimiento (3-5 años) ",
				},
			],
		},
		{
			id: 5,
			text: "Ciclofosfamida",
			answers: [
				{
					id: 6,
					selected: false,
					correct: true,
					text: "3 meses de tratamiento de inicio con MPAA de mantenimiento",
				},
				{
					id: 7,
					selected: false,
					correct: false,
					text: "6 meses de tratamiento de inicio con MPAA de mantenimiento",
				},
				{
					id: 8,
					selected: false,
					correct: false,
					text: "1 año de tratamiento de inicio con MPAA de mantenimiento",
				},
			],
		},
		{
			id: 9,
			text: "Inhibidores de la calcineurina (ICN) ",
			answers: [
				{
					id: 10,
					selected: false,
					correct: false,
					text: "6-12 meses, luego 6-9 meses de retirada gradual",
				},
				{
					id: 11,
					selected: false,
					correct: true,
					text: "12-18 meses, luego 6-12 meses de retirada gradual",
				},
				{
					id: 12,
					selected: false,
					correct: false,
					text: "18-24 meses, luego 12-18 meses de retirada gradual",
				},
			],
		},
	]);

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		return (
			<div>
				<div
					className="bg-white p-3 bold txt-black txt18 d-flex align-items-center justify-content-center text-center"
					style={{ borderRadius: "4px", height: "10vh" }}
				>
					{option?.text}
				</div>
				{option?.answers?.map((answer) => {
					const selected = answer?.selected;

					function getBackgroundColor() {
						return answer?.selected &&
							answer?.correct &&
							isCompleted
							? "#D6D431"
							: answer?.selected &&
							  !answer?.correct &&
							  isCompleted
							? "#FF5948"
							: answer?.selected
							? "#FF7F6B"
							: "#FFFFFF";
					}

					function handleOptionClick() {
						if (isCompleted) return;

						let updatedOptions = options.map((x) =>
							x.id === id
								? {
										...x,
										answers: x?.answers?.map((a) =>
											a.id === answer?.id
												? {
														...a,
														selected: !a.selected,
												  }
												: { ...a, selected: false }
										),
								  }
								: { ...x, selected: false }
						);

						setOptions(updatedOptions);
					}

					return (
						<div
							className={`d-flex align-items-center justify-content-start txt-black mt-3 p-3 ${
								selected ? "option-selected txt-white bold" : ""
							}`}
							style={{
								height: "fit-content",
								lineHeight: "20px",
								borderRadius: "4px",
								backgroundColor: getBackgroundColor(),
								opacity: selected ? "1" : "0.8",
							}}
							onClick={handleOptionClick}
						>
							<div className="d-flex align-items-start justify-content-start">
								<div className="ms-2">{answer?.text}</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	function checkAnswers() {
		if (!options?.every((x) => x?.answers?.some((y) => y.selected))) {
			alertError({
				error: " ",
				customMessage:
					"Para avanzar, debes seleccionar una opción para cada elemento.",
			});
			return;
		}

		const wrongAnswers = options?.filter((x) =>
			x?.answers?.some((y) => y.selected && y?.selected !== y?.correct)
		);
		const correct = wrongAnswers?.length === 0;

		const explanation = `<div class="text-left txt14 px-5">
				<div class="mt-4">
					Esta es la recomendación de la guía GLOSEN 2023<sup>1</sup> sobre la duración del tratamiento: 
				</div>
				<div class="mt-2">
					<span class="bold">Análogos del ácido micofenólico (MPAA):</span> Continuar como tratamiento de mantenimiento (3-5 años). 
				</div>
				<div class="mt-2">
					<span class="bold">Ciclofosfamida:</span> 3 meses de tratamiento con MPAA de mantenimiento. 
				</div>
				<div class="mt-2">
					<span class="bold">Inhibidores de la calcineurina (ICN):</span> 12-18 meses, luego 6-12 meses de retirada gradual. 
				</div>				
				<div class="mt-4 txt10">
					<sup>1</sup>Rojas-Rivera JE, García-Carro C, Ávila A, et al. Documento de consenso del Grupo de Estudio de Enfermedades Glomerulares de la Sociedad Española de Nefrología (GLOSEN) para el diagnóstico y tratamiento de la nefritis lúpica. Nefrología. 2023;43:6-47.
				</div>
			</div>`;

		if (correct) {
			const text = `<div class="txt14 txt-black">
			<div class="text-center bold txt-turquoise txt18">
				¡Correcto!
			</div>
			<div class="d-flex justify-content-center mt-3">
				<div class="bg-correct p-2 w-fit-content">
					<img width="25" src=${handGood} class="me-1" />
					<span class="bold">+${CORRECT_ANSWER_POINTS}</span> puntos
				</div>
			</div>
			${explanation}
		</div>`;

			modalGeneric({ html: text }).then((res) => {
				if (res.isConfirmed) submitAnswer({ correct: correct });
			});
		} else {
			const text = `<div class="text-center">
					<div class="bold txt-turquoise">
						${
							intentos < 3
								? `Hay ${wrongAnswers?.length} fallo(s).`
								: "¡Vaya! Aún hay algún fallo."
						}					
					</div>					
					<div class="txt-black mt-3">${
						intentos < 3 ? "Inténtalo otra vez." : explanation
					}</div>
				</div>`;

			if (intentos < 3) modalGenericSmall({ html: text });
			else
				modalGeneric({ html: text }).then((res) => {
					if (res.isConfirmed) submitAnswer({ correct: correct });
				});
		}
		setIntentos(intentos + 1);
	}

	async function submitAnswer({ correct }) {
		setIsCompleted(true);

		const score = correct && intentos <= 3 ? CORRECT_ANSWER_POINTS : 0;

		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateScore(score, SCORE_TYPES.SCORE));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						lastVisited: NAVIGATE_TO,
					});
				}
			})
			.catch((error) => {});
	}

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					<div className="d-flex justify-content-center txtWhite txt20 bold">
						Indica la duración del tratamiento recomendada por la
						guía GLOSEN 2023. {" "}
					</div>
					<div className="row d-flex justify-content-center align-items-start mt-4">
						{options.map((option) => {
							return (
								<div className="col">
									<SelectOption id={option.id} />
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					right: "4vh",
					bottom: "17vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					<div
						className="btn-main"
						onClick={() => {
							if (isCompleted)
								modalQuestionInfo({
									html: QUESTION_EXPLANATION,
								}).then((res) => {
									if (res.isConfirmed) navigate(NAVIGATE_TO);
								});
							else checkAnswers();
						}}
					>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario7);
