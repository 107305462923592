import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import cellImageBattery from "../../assets/cell-image-battery.png";
import logoMedical from "../../assets/logo-medical.png";
import hand from "../../assets/mano.png";
import messageFrame from "../../assets/message-frame.png";

import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen, handleNavigate } from "../../utils/helpers";
import { modalQuestionInfo } from "../../utils/logger";

function Scenario3Intro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const group = useSelector((state) => state.group);

	const QUESTION_INFO = `<div class="d-flex flex-column align-items-start justify-content-center txt-turquoise">
			<div class="txt-turquoise bold txt20 text-center">
				Aquí tienes una batería de 4 imágenes y 4 etiquetas.
			</div>
			<div class="d-flex justify-content-center w-100 mt-4">
				<img src=${cellImageBattery} />
			</div>
			<div class="d-flex align-items-start mt-4">
				<img width="10%" src=${hand} />
				<ul>
					<li>Relaciónalas correctamente.</li>
					<li class="mt-2">
						Cuando las 4 imágenes tengan una etiqueta asignada,
						pulsa <strong style="color: #FF7F6B">Confirmar</strong> y comprueba tus aciertos.
					</li>
					<li>
						Las imágenes con la descripción correcta se quedarán
						fijas.
					</li>
					<li>
						Debes acertar las 4 imágenes para superar la prueba.
					</li>
				</ul>
			</div>
		</div>`;

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					style={{ paddingLeft: "6vh" }}
					className="row d-flex justify-content-start align-items-start mt-5 pt-5"
				>
					<div className="col-3 txtWhite">
						<div
							className="txtWhite txt20"
							style={{ letterSpacing: 3 }}
						>
							ESCENARIO 3
						</div>
						<div
							className="txtWhite txt30 bold mt-3"
							style={{ letterSpacing: 3, lineHeight: "30px" }}
						>
							Diagnóstico
							<br />
							por imagen
						</div>
						<div className="mt-4">
							Recibes un correo electrónico de un colega:
						</div>
					</div>
					<div
						className="d-flex justify-content-end"
						style={{
							position: "absolute",
							right: "0%",
							bottom: "15%",
						}}
					>
						<div
							className="col-9 d-flex justify-content-end"
							onClick={() =>
								modalQuestionInfo({ html: QUESTION_INFO }).then(
									(res) => {
										if (res.isConfirmed)
											handleNavigate({
												group: group,
												navigate: navigate,
												dispatch: dispatch,
												navigateTo: ROUTES.SCENARIO3,
												scenarioNum:
													getScenarioByScreen(
														ROUTES.SCENARIO3
													),
											});
									}
								)
							}
						>
							<img height={480} src={messageFrame} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario3Intro);
