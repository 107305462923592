import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import hand from "../../assets/mano.png";
import esquema from "../../assets/scenario5-esquema.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function Scenario5Explanation({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const scenarioNum = getScenarioByScreen(location.pathname);

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					<div className="d-flex justify-content-start align-items-center">
						<img src={hand} />
						<div className="txt-white ms-2">
							Fíjate bien en este gráfico y avanza para responder
							la pregunta.
						</div>
					</div>
					<div className="d-flex justify-content-start align-items-center">
						<img width={"95%"} src={esquema}></img>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "13vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton
						navigateTo={ROUTES.SCENARIO5}
						text={"IR A LA PREGUNTA"}
					/>
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario5Explanation);
