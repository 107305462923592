import React, { useState } from "react";
import hideIcon from "../assets/scenariobar/hide.png";
import scenarioInactive from "../assets/scenariobar/scenario-inactive.png";
import scenarioPassed from "../assets/scenariobar/scenario-passed.png";
import scenario1Active from "../assets/scenariobar/scenario1-active.png";
import scenario2Active from "../assets/scenariobar/scenario2-active.png";
import scenario3Active from "../assets/scenariobar/scenario3-active.png";
import scenario4Active from "../assets/scenariobar/scenario4-active.png";
import scenario5Active from "../assets/scenariobar/scenario5-active.png";
import scenario6Active from "../assets/scenariobar/scenario6-active.png";
import scenario7Active from "../assets/scenariobar/scenario7-active.png";

function ScenarioBar({ activeScenario, expanded }) {
	const [scenarioBarOpen, setScenarioBarOpen] = useState(expanded || true);

	function handleClick(e) {
		if (!scenarioBarOpen) setScenarioBarOpen(true);
		else setScenarioBarOpen(false);
	}

	const ScenarioItem = ({ title, scenarioNum }) => {
		const active = activeScenario === scenarioNum;
		const passed = activeScenario > scenarioNum;

		function getImageByScenario() {
			if (passed) return scenarioPassed;
			else if (!active) return scenarioInactive;
			else if (active) {
				if (scenarioNum === 1) return scenario1Active;
				else if (scenarioNum === 2) return scenario2Active;
				else if (scenarioNum === 3) return scenario3Active;
				else if (scenarioNum === 4) return scenario4Active;
				else if (scenarioNum === 5) return scenario5Active;
				else if (scenarioNum === 6) return scenario6Active;
				else if (scenarioNum === 7) return scenario7Active;
				else return scenario1Active;
			}
		}

		return (
			<div className="d-flex flex-column align-items-center text-center">
				<div className={`px-1 ${passed ? "txt-yellow" : "txtWhite"}`}>
					{title}
				</div>
				<img className="mt-2" width={50} src={getImageByScenario()} />
			</div>
		);
	};

	return (
		<div className="scenarioBar">
			<div
				className={`h-100 px-4 d-flex align-items-center justify-content-start`}
			>
				<div className="me-5 mt-5">
					<div className="hide-container" onClick={handleClick}>
						<img
							width={25}
							src={scenarioBarOpen ? hideIcon : hideIcon}
							alt="Esconder menú"
						/>
					</div>
				</div>
				<div
					className={`row d-flex align-items-end justify-content-around  ${
						!scenarioBarOpen ? "d-none" : ""
					}`}
				>
					<div className="col">
						<ScenarioItem
							title={"Pruebas diagnósticas"}
							scenarioNum={1}
						/>
					</div>
					<div className="col">
						<ScenarioItem title={"Diagnóstico"} scenarioNum={2} />
					</div>
					<div className="col">
						<ScenarioItem
							title={"Diagnóstico por imagen"}
							scenarioNum={3}
						/>
					</div>
					<div className="col">
						<ScenarioItem title={"Tratamiento"} scenarioNum={4} />
					</div>
					<div className="col">
						<ScenarioItem
							title={"Actitud terapéutica"}
							scenarioNum={5}
						/>
					</div>
					<div className="col">
						<ScenarioItem
							title={"Ajuste de dosis"}
							scenarioNum={6}
						/>
					</div>
					<div className="col">
						<ScenarioItem
							title={"Tiempo de tratamiento"}
							scenarioNum={7}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ScenarioBar;
