import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMedical from "../../assets/logo-medical.png";
import martina from "../../assets/martina.png";
import NextButton from "../../components/NextButton";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function Scenario3Result({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const scenarioNum = getScenarioByScreen(location.pathname);

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5 pt-5">
					<div className="col-9">
						<div className="d-flex flex-column align-items-center">
							<img width={90} src={martina} />
							<div
								className="txt30 bold txt-white mt-4 text-center"
								style={{ lineHeight: "40px" }}
							>
								¡Ahora ya puedes seguir con <br />
								el caso de Martina!
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton
						navigateTo={ROUTES.TRANSITION_SCENARIOS}
						activeScenario={scenarioNum + 1}
					/>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario3Result);
