import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import estrella from "../assets/estrella.png";
import logoMedical from "../assets/logo-medical.png";
import { formatScoreTopBar } from "../utils/helpers";

function End({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const score = useSelector((state) => state.group?.score);

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					className="row d-flex justify-content-center align-items-center"
					style={{ marginTop: "15vh" }}
				>
					<div className="col-8">
						<div className="ps-5 txt16 text-center">
							<div
								className="txt20 bold txt-yellow-light mt-4"
								style={{ lineHeight: "30px" }}
							>
								¡Ahora sí! Has llegado al final de Medical
								Challenge L.{" "}
							</div>
							<div className="txt-white mt-4">
								Muchas gracias por tu participación.
							</div>
							<div className="txt-white mt-4 bold">
								¿Quieres conocer tu puntuación?{" "}
							</div>
							<div className="d-flex justify-content-center align-items-center mt-4">
								{Array.from({ length: 5 }).map((_, index) => (
									<img
										key={index}
										src={estrella}
										className="me-2"
										alt="star"
										width="25px"
									/>
								))}
							</div>
							<div
								className="txt-white mt-4 bold txt30"
								style={{ letterSpacing: "3px" }}
							>
								{formatScoreTopBar(score)}
							</div>
						</div>
					</div>
					<div
						className="txtWhite d-flex justify-content-end txt12 text-right"
						style={{
							position: "absolute",
							bottom: "8vh",
							right: "5vh",
						}}
					>
						ES-LUP-2400168 v1 <br />
						Fecha de elaboración: Septiembre 2024
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(End);
