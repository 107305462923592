import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import handGood from "../../assets/hand-good.png";
import handWrong from "../../assets/hand-wrong.png";
import logoMedical from "../../assets/logo-medical.png";
import martinaCalendar from "../../assets/martina-calendar.png";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen, handleNavigate } from "../../utils/helpers";
import { modalQuestionInfo } from "../../utils/logger";

const QUESTION_EXPLANATION = `<div class="txt-black text-center">
		<div class="txt-turquoise bold">
			Indica la duración del tratamiento recomendada por la guía GLOSEN 2023<sup>1</sup>. 
		</div>
		<div class="mt-4">
			Escoge una opción para cada elemento. Tienes 3 intentos.
		</div>
		<div class="w-100 px-5">		
			<div class="d-flex align-items-center justify-content-between mt-5 px-5">
				<div class="w-50 text-left">Todas las opciones correctas en alguno de los 3 intentos: </div>
				<div class="bg-correct p-2" style="width: 150px;">
					<img width="25" src=${handGood} class="me-1" />
					<span class="bold">+50</span> puntos
				</div>
			</div>
			<div class="d-flex align-items-center justify-content-between mt-3 px-5">
				<div class="">En otro caso:</div>
				<div class="bg-incorrect p-2" style="width: 150px;">
					<img width="18" src=${handWrong} class="ms-1 me-2" />
					<span class="bold">0</span> puntos
				</div>
			</div>
		</div>
		<div class="mt-4 txt10">
			<sup>1</sup>Rojas-Rivera JE, García-Carro C, Ávila A, et al. Documento de consenso del Grupo de Estudio de Enfermedades Glomerulares de la Sociedad Española de Nefrología (GLOSEN) para el diagnóstico y tratamiento de la nefritis lúpica. Nefrología. 2023;43:6-47.
		</div>
	</div>`;

function Scenario7Intro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const group = useSelector((state) => state.group);

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					style={{ paddingLeft: "6vh", paddingRight: "6vh" }}
					className="row d-flex justify-content-start align-items-start mt-5"
				>
					<div className="col-3 txtWhite">
						<div
							className="txtWhite txt20"
							style={{ letterSpacing: 3 }}
						>
							ESCENARIO 7
						</div>
						<div
							className="txtWhite txt30 bold mt-3"
							style={{ letterSpacing: 3, lineHeight: "35px" }}
						>
							Tiempo de tratamiento{" "}
						</div>
						<div
							className="bold mt-4"
							style={{ lineHeight: "20px" }}
						>
							Han pasado 2 meses más
						</div>
					</div>
					<div className="col-3">
						<img src={martinaCalendar} />
					</div>
					<div className="col-6 text-center txt-white txt18">
						<div>
							Martina ya se recuperó de su gastroenteritis aguda y
							ha vuelto a la normalización de la dosis de
							voclosporina y de su función renal. 
						</div>
						<div className="my-4">Ahora la pregunta es:</div>
						<div className="txt-yellow bold txt20 mt-5">
							¿Durante cuánto tiempo más debería mantenerse el
							tratamiento?{" "}
							<div className="mt-4">
								¿Qué dicen las guías al respecto? 
							</div>
						</div>
					</div>
					<div
						className="container"
						style={{
							position: "absolute",
							right: "7vh",
							bottom: "4vh",
						}}
					>
						<div className="d-flex justify-content-end">
							<div
								className="btn-main me-3"
								onClick={() =>
									modalQuestionInfo({
										html: QUESTION_EXPLANATION,
									}).then((res) => {
										if (res.isConfirmed)
											handleNavigate({
												group: group,
												navigate: navigate,
												dispatch: dispatch,
												navigateTo: ROUTES.SCENARIO7,
												scenarioNum:
													getScenarioByScreen(
														ROUTES.SCENARIO7
													),
											});
									})
								}
							>
								<div className="txt-btn-main">AVANZAR</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario7Intro);
