import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import handWrong from "../../assets/hand-wrong.png";
import logoMedical from "../../assets/logo-medical.png";
import mensaje1 from "../../assets/scenario6-mensaje1.png";
import mensaje10 from "../../assets/scenario6-mensaje10.png";
import mensaje2 from "../../assets/scenario6-mensaje2.png";
import mensaje3 from "../../assets/scenario6-mensaje3.png";
import mensaje4 from "../../assets/scenario6-mensaje4.png";
import mensaje5 from "../../assets/scenario6-mensaje5.png";
import mensaje6 from "../../assets/scenario6-mensaje6.png";
import mensaje7 from "../../assets/scenario6-mensaje7.png";
import mensaje8 from "../../assets/scenario6-mensaje8.png";
import mensaje9 from "../../assets/scenario6-mensaje9.png";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen, handleNavigate } from "../../utils/helpers";
import { modalInformeMedico, modalQuestionInfo } from "../../utils/logger";

const QUESTION_EXPLANATION = `<div class="txt-black text-center">
		<div class="txt-turquoise bold">
			¿Sabes cómo ajustar voclosporina en función de la disminución de la
			tasa de filtración glomerular estimada (TFGe)? 
		</div>
		<div class="mt-4">
			Relaciona los valores de disminución confirmada de la TFGe desde el
			inicio con las recomendaciones de dosificación de voclosporina.
			Pulsa <span class="bold">Confirmar</span> para comprobar si has
			acertado.     
		</div>
		<div class="mt-4">Cada intento con al menos un error:</div>
		<div class="d-flex justify-content-center mt-4">
			<div class="bg-incorrect txt-black w-fit-content p-2">
				<img width="20" src=${handWrong} class="me-1" />
				-10 puntos
			</div>
		</div>
		<div class="mt-4">(hasta un máximo de -50 puntos).</div>
	</div>`;

function Scenario6Intro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const group = useSelector((state) => state.group);

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					style={{ paddingLeft: "6vh" }}
					className="row d-flex justify-content-start align-items-start mt-5"
				>
					<div className="col-4 txtWhite">
						<div
							className="txtWhite txt20"
							style={{ letterSpacing: 3 }}
						>
							ESCENARIO 6
						</div>
						<div
							className="txtWhite txt30 bold mt-3"
							style={{ letterSpacing: 3, lineHeight: "35px" }}
						>
							Ajuste de dosis{" "}
						</div>
						<div style={{ lineHeight: "20px" }}>
							<div className="bold mt-4">
								Ha transcurrido un mes desde la última visita de
								Martina. 
							</div>
							<div className="mt-3">
								Estás en tu consulta cuando recibes una
								llamada. 
							</div>
						</div>
					</div>
					<div className="col-8" style={{ marginTop: "-15vh" }}>
						<div className="d-flex justify-content-start">
							<img width={"20%"} src={mensaje1} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={"56%"} src={mensaje2} />
						</div>
						<div className="d-flex justify-content-start">
							<img width={"28.5%"} src={mensaje3} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={"61%"} src={mensaje4} />
						</div>
						<div className="d-flex justify-content-start">
							<img width={"73%"} src={mensaje5} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={"61%"} src={mensaje6} />
						</div>
						<div className="d-flex justify-content-start">
							<img width={"37.5%"} src={mensaje7} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={"61%"} src={mensaje8} />
						</div>
						<div className="d-flex justify-content-start">
							<img width={"62%"} src={mensaje9} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={"57.5%"} src={mensaje10} />
						</div>
					</div>
					<div
						className="container"
						style={{
							position: "absolute",
							right: "7vh",
							bottom: "4vh",
						}}
					>
						<div className="d-flex justify-content-end">
							<div
								className="btn-main-cancel me-3"
								onClick={() => modalInformeMedico()}
							>
								<div className="txt-btn-main">VER INFORME</div>
							</div>
							<div
								className="btn-main me-3"
								onClick={() =>
									modalQuestionInfo({
										html: QUESTION_EXPLANATION,
									}).then((res) => {
										if (res.isConfirmed)
											handleNavigate({
												group: group,
												navigate: navigate,
												dispatch: dispatch,
												navigateTo: ROUTES.SCENARIO6,
												scenarioNum:
													getScenarioByScreen(
														ROUTES.SCENARIO6
													),
											});
									})
								}
							>
								<div className="txt-btn-main">AVANZAR</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario6Intro);
