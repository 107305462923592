import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import handGood from "../../assets/hand-good.png";
import handWrong from "../../assets/hand-wrong.png";
import iconCorrect from "../../assets/icon-correct.png";
import scenario2Table from "../../assets/scenario2-table.png";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateScore, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertError,
	modalConfirm,
	modalQuestion2Feedback,
	modalQuestionInfo,
} from "../../utils/logger";

const QUIZ_ID = QUIZZES.SCENARIO2;
const SCENARIO_ID = SCENARIOS.SCENARIO_2;
const NAVIGATE_TO = ROUTES.SCENARIO2_RESULT;

const CORRECT_ANSWER_POINTS = 10;
const FIRST_ATTEMPT_EXTRA_POINTS = 20;
const WRONG_ANSWER_POINTS = -10;

function Scenario2({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const participant = useSelector((state) => state.group);

	const scenarioNum = getScenarioByScreen(location.pathname);

	const [points, setPoints] = useState({ score: 0, bonus: 0, penalty: 0 });
	const [time, setTime] = useState(0);

	const [isCompleted, setIsCompleted] = useState(false);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const [intentos, setIntentos] = useState(1);

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "Diagnóstico 1",
			selected: false,
			correct: false,
			content: (
				<div className="txt-turquoise">
					<div>Nefritis lúpica clase II</div>
					<div>Índice de actividad: 8/24</div>
					<div>Índice de cronicidad: 3/12</div>
				</div>
			),
		},
		{
			id: 2,
			text: "Diagnóstico 2",
			selected: false,
			correct: false,
			content: (
				<div className="txt-turquoise">
					<div>Nefritis lúpica clase V</div>
					<div>Índice de actividad: 15/24</div>
					<div>Índice de cronicidad: 2/12</div>
				</div>
			),
		},
		{
			id: 3,
			text: "Diagnóstico 3",
			selected: false,
			correct: true,
			content: (
				<div className="txt-turquoise">
					<div>Nefritis lúpica clase IV</div>
					<div>Índice de actividad: 12/24</div>
					<div>Índice de cronicidad: 1/12</div>
				</div>
			),
		},
		{
			id: 4,
			text: "Diagnóstico 4",
			selected: false,
			correct: false,
			content: (
				<div className="txt-turquoise">
					<div>Nefritis lúpica clase III</div>
					<div>Índice de actividad: 10/24</div>
					<div>Índice de cronicidad: 6/12</div>
				</div>
			),
		},
	]);

	useEffect(() => {
		const QUESTION_INFO = `<div class="d-flex flex-column align-items-start justify-content-center">
		<div class="txt-turquoise bold txt20 text-center">
		A la vista de los resultados de las pruebas y de este informe, ¿qué diagnóstico te parece más probable?
		</div>
		<div class="w-100 px-5">		
		<div class="d-flex align-items-center justify-content-between mt-5">
			<div class="">Diagnóstico correcto:</div>
			<div class="bg-correct p-2" style="width: 150px;">
				<img width="25" src=${handGood} class="me-1" />
				<span class="bold">+${CORRECT_ANSWER_POINTS}</span> puntos
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-between mt-3">
			<div class="">Diagnóstico correcto al primer intento:</div>
			<div class="bg-correct p-2" style="width: 150px;">
				<img width="25" src=${handGood} class="me-2" />
				<span class="bold">+${FIRST_ATTEMPT_EXTRA_POINTS}</span> puntos
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-between mt-3">
			<div class="">Diagnóstico correcto al primer intento:</div>
			<div class="bg-incorrect p-2" style="width: 150px;">
				<img width="20" src=${handWrong} class="me-2" />
				<span class="bold">${WRONG_ANSWER_POINTS}</span> puntos
			</div>
		</div>
		</div>
	</div>`;
		modalQuestionInfo({ html: QUESTION_INFO });
	}, []);

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		function handleOptionClick() {
			if (isCompleted) return;

			let updatedOptions = options.map((x) =>
				x.id === id ? { ...x, selected: true } : { ...x }
			);

			setOptions(updatedOptions);

			modalConfirm({
				text: "¿Estás seguro de tu elección?",
			}).then((res) => {
				if (res.isConfirmed) {
					setIntentos(intentos + 1);
					let updatedOptions = options.map((x) =>
						x.id === id
							? { ...x, selected: true, checked: true }
							: { ...x }
					);

					setOptions(updatedOptions);

					let correct = option?.correct;
					setIsCompleted(correct);

					let score = correct
						? CORRECT_ANSWER_POINTS +
						  (intentos === 1 ? FIRST_ATTEMPT_EXTRA_POINTS : 0)
						: WRONG_ANSWER_POINTS;

					setPoints({
						...points,
						score: points.score + score,
						bonus: intentos === 1 ? FIRST_ATTEMPT_EXTRA_POINTS : 0,
						penalty: !correct
							? points.penalty + WRONG_ANSWER_POINTS
							: points.penalty,
					});

					modalQuestion2Feedback({
						question: correct
							? "¡Efectivamente! ¡Este es el diagnóstico correcto!"
							: "Este diagnóstico no es probable.",
						answerHtml: correct
							? `<img width="100%" src=${scenario2Table} />`
							: "Inténtalo otra vez.",
						correct: correct,
						points: score,
					}).then((res) => {
						if (!correct) {
							let updatedOptions = options.map((x) =>
								x.id === id
									? { ...x, selected: false }
									: { ...x }
							);

							setOptions(updatedOptions);
						}
					});
				} else {
					let updatedOptions = options.map((x) =>
						x.id === id ? { ...x, selected: false } : { ...x }
					);

					setOptions(updatedOptions);
				}
			});
		}

		function getBackgroundColor() {
			return option?.selected && option?.correct && isCompleted
				? "#D6D431"
				: option?.selected && !option?.correct && isCompleted
				? "#FF5948"
				: option?.selected
				? "#EAF886"
				: "#FFFFFF";
		}

		return (
			<div>
				<div
					className={`d-flex flex-column align-items-center justify-content-center txt-turquoise text-center mt-3 ${
						option?.selected ? "option-selected" : ""
					}`}
					style={{
						position: "relative",
						height: "20vh",
						borderRadius: "10px",
						backgroundColor: getBackgroundColor(),
					}}
					onClick={handleOptionClick}
				>
					<div className="bold mb-2">{option?.text}</div>
					{option?.content}
					{isCompleted && option?.correct && (
						<img
							style={{ position: "absolute", top: 10, right: 10 }}
							width={20}
							src={iconCorrect}
						/>
					)}
				</div>
			</div>
		);
	};

	async function submitAnswer() {
		if (!isCompleted) {
			alertError({
				error: " ",
				customMessage:
					"Para avanzar, debes escoger el diagnóstico correcto. ¡Sigue intentándolo!",
			});
			return;
		}

		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: participant?._id,
			score: points.score,
			time: time,
			correct: true,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateScore(points.score, SCORE_TYPES.SCORE));
					dispatch(updateScore(points.bonus, SCORE_TYPES.BONUS));
					dispatch(updateScore(points.penalty, SCORE_TYPES.PENALTY));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + points.score,
						bonus: participant?.bonus + points.bonus,
						penaltyScore:
							participant?.penaltyScore + points.penalty,
						lastVisited: NAVIGATE_TO,
					});
					navigate(NAVIGATE_TO, {
						state: { activeScenario: scenarioNum + 1 },
					});
				}
			})
			.catch((error) => {});
	}

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					<div className="d-flex justify-content-center align-items-center my-4">
						<div className="col-7 text-center">
							<div className="txtWhite txt20 bold">
								A la vista de los resultados de las pruebas y de
								este informe, ¿qué diagnóstico te parece más
								probable? 
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-center align-items-center">
						<div className="row col-7">
							{options.map((option) => {
								return (
									<div className="col-6">
										<SelectOption id={option.id} />
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "13vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					<div className="btn-main" onClick={() => submitAnswer()}>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario2);
