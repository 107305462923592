import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import arrowLeft from "../../assets/arrow-left.png";
import arrowRight from "../../assets/arrow-right.png";
import buttonAmpliar from "../../assets/button-ampliar.png";
import cell1 from "../../assets/cell1.png";
import cell2 from "../../assets/cell2.png";
import cell3 from "../../assets/cell3.png";
import cell4 from "../../assets/cell4.png";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateScore, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertError,
	modalGenericSmall,
	modalImage,
	modalQuestion3Feedback,
} from "../../utils/logger";

const QUIZ_ID = QUIZZES.SCENARIO3;
const SCENARIO_ID = SCENARIOS.SCENARIO_3;
const NAVIGATE_TO = ROUTES.SCENARIO3_EXPLANATION;

const CORRECT_ANSWER_POINTS = 40;

function Scenario3({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const participant = useSelector((state) => state?.group);

	const scenarioNum = getScenarioByScreen(location.pathname);

	const [time, setTime] = useState(0);

	const [isCompleted, setIsCompleted] = useState(false);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "Semiluna celular 2 de 3",
			selectedImage: [false, false, false, false],
			correctImage: cell3,
			checked: false,
		},
		{
			id: 2,
			text: "Inflamación intersticial",
			selectedImage: [false, false, false, false],
			correctImage: cell4,
			checked: false,
		},
		{
			id: 3,
			text: "Depósitos subendoteliales (trombos hialinos)",
			selectedImage: [false, false, false, false],
			correctImage: cell2,
			checked: false,
		},
		{
			id: 4,
			text: "Depósitos subendoteliales (asas en alambre)",
			selectedImage: [false, false, false, false],
			correctImage: cell1,
			checked: false,
		},
	]);

	const images = [cell1, cell2, cell3, cell4];
	const [activeImage, setActiveImage] = useState(cell1);

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		const index = images.findIndex((x) => x === activeImage);

		const selectedImage = option?.selectedImage[index];

		function handleOptionClick() {
			if (isCompleted) return;

			let updatedOptions = options.map((x) => {
				if (x.id === id) {
					let updatedSelected = [...x.selectedImage];
					if (selectedImage) updatedSelected[index] = false;
					else updatedSelected[index] = images[index];

					return {
						...x,
						selectedImage: updatedSelected,
					};
				} else {
					let updatedSelected = [...x.selectedImage];
					updatedSelected[index] = false;
					return {
						...x,
						selectedImage: updatedSelected,
					};
				}
			});

			setOptions(updatedOptions);
		}

		function getBackgroundColor() {
			return selectedImage &&
				option?.correctImage === selectedImage &&
				option?.checked
				? "#D6D431"
				: selectedImage
				? "#EAF886"
				: "#FFFFFF";
		}

		return (
			<div>
				<div
					className={`d-flex flex-column align-items-center justify-content-center txt-turquoise text-center mt-3 p-2 ${
						selectedImage ? "option-selected" : ""
					}`}
					style={{
						height: "8vh",
						borderRadius: "10px",
						backgroundColor: getBackgroundColor(),
					}}
					onClick={handleOptionClick}
				>
					<div className="bold">{option?.text}</div>
					{option?.content}
				</div>
			</div>
		);
	};

	const ImageCarousel = () => {
		const activeIndex = images.findIndex((x) => x === activeImage);

		const nextImage = () => {
			const nextIndex = (activeIndex + 1) % images.length;
			setActiveImage(images[nextIndex]);
		};

		const prevImage = () => {
			const prevIndex = (activeIndex - 1 + images.length) % images.length;
			setActiveImage(images[prevIndex]);
		};

		return (
			<div className="d-flex align-items-center">
				{isCompleted && <img onClick={prevImage} src={arrowLeft} />}
				<div className="image-carousel-container d-flex justify-content-center w-100 mx-3">
					<img height={400} src={activeImage} />
					<div
						style={{
							position: "absolute",
							bottom: "5%",
							right: "5%",
						}}
					>
						<img
							onClick={() => modalImage({ image: activeImage })}
							width={70}
							src={buttonAmpliar}
						/>
					</div>
				</div>
				{isCompleted && <img onClick={nextImage} src={arrowRight} />}
			</div>
		);
	};

	function checkAnswers() {
		function setCurrentOptionAsChecked() {
			const updatedOptions = options.map((option) => {
				if (!option.selectedImage?.includes(option.correctImage)) {
					return {
						...option,
						selectedImage: [false, false, false, false],
					};
				} else return { ...option, checked: true };
			});
			setOptions(updatedOptions);
		}

		const activeIndex = images.findIndex((x) => x === activeImage);

		if (options?.every((x) => !x.selectedImage[activeIndex])) {
			alertError({
				error: " ",
				customMessage: "Para avanzar debes seleccionar una opción.",
			});
			return;
		} else if (
			!options?.some(
				(x) => x.selectedImage[activeIndex] === x.correctImage
			)
		) {
			const text = `<div class="text-center">
			<p class="txt-turquoise bold">La etiqueta es incorrecta.</p>
			<p class="txt-black">Inténtalo de nuevo</p>
		</div>`;

			modalGenericSmall({
				html: text,
				confirmButtonText: "INTENTAR DE NUEVO",
				allowOutsideClick: true,
			});
		} else if (activeIndex < images.length - 1) {
			const text = `<div class="text-center">
					<p class="txt-turquoise bold">
						¡Correcto!
					</p>
					<p class="txt-black">
						¡A por la siguiente!
					</p>
				</div>`;

			modalGenericSmall({
				html: text,
				confirmButtonText: "AVANZAR",
				allowOutsideClick: false,
			}).then((res) => {
				if (res.isConfirmed) {
					setActiveImage(
						images[
							(images.indexOf(activeImage) + 1) % images.length
						]
					);

					setCurrentOptionAsChecked();
				}
			});
		} else {
			const text = `<div class="text-center">
					<p class="txt-turquoise bold">
						¡Excelente, todo está correcto!
					</p>
					<p class="txt-black">
						Echa un vistazo a la solución y ya podrás responder al
						correo de Ricardo indicándole qué muestra cada imagen.
						Por el hecho de superar esta prueba, has ganado:
					</p>
				</div>`;

			modalQuestion3Feedback({
				text,
				correct: true,
				points: CORRECT_ANSWER_POINTS,
			}).then((res) => {
				if (res.isConfirmed) {
					setCurrentOptionAsChecked();
					submitAnswer();
				}
			});
		}
	}

	async function submitAnswer() {
		setIsCompleted(true);

		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: participant?._id,
			score: CORRECT_ANSWER_POINTS,
			time: time,
			correct: true,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(
						updateScore(CORRECT_ANSWER_POINTS, SCORE_TYPES.SCORE)
					);

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + CORRECT_ANSWER_POINTS,
						lastVisited: NAVIGATE_TO,
					});
				}
			})
			.catch((error) => {});
	}

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					<div className="row d-flex justify-content-center align-items-center my-4">
						<div className="col-8 px-4">
							<ImageCarousel />
						</div>
						<div className="col-4">
							{options.map((option) => {
								return (
									<div>
										<SelectOption id={option.id} />
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "13vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					<div
						className="btn-main"
						onClick={() => {
							if (!isCompleted) checkAnswers();
							else
								navigate(NAVIGATE_TO, {
									state: { activeScenario: scenarioNum + 1 },
								});
						}}
					>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario3);
