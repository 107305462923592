import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import handGood from "../../assets/hand-good.png";
import handWrong from "../../assets/hand-wrong.png";
import iconFalseCorrect from "../../assets/icon-false-correct.png";
import iconFalseSelected from "../../assets/icon-false-selected.png";
import iconFalseWrong from "../../assets/icon-false-wrong.png";
import iconFalse from "../../assets/icon-false.png";
import iconTrueCorrect from "../../assets/icon-true-correct.png";
import iconTrueSelected from "../../assets/icon-true-selected.png";
import iconTrueWrong from "../../assets/icon-true-wrong.png";
import iconTrue from "../../assets/icon-true.png";
import hand from "../../assets/mano.png";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateScore, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertError,
	modalGenericSmall,
	modalQuestionInfo,
} from "../../utils/logger";

const QUESTION_INFO = `<div class="d-flex flex-column align-items-start justify-content-center txt16">
		<div class="txt-turquoise bold txt20 text-center">
		Todas las afirmaciones siguientes sobre voclosporina son ciertas, excepto una. ¿Sabías identificar cuál es la «afirmación infiltrada»?
		</div>
		<div class="w-100 px-5">		
		<div class="d-flex align-items-center justify-content-between mt-5">
			<div class="">Identificación de la infiltrada</div>
			<div class="bg-correct p-2" style="width: 150px;">
				<img width="25" src=${handGood} class="me-1" />
				<span class="bold">+${10}</span> puntos
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-between mt-3">
			<div class="w-50">Identificación de la infiltrada al primer intento</div>
			<div class="bg-correct p-2" style="width: 150px;">
				<img width="25" src=${handGood} class="me-2" />
				<span class="bold">+${20}</span> puntos
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-between mt-3">
			<div class="w-50">Cada identificación incorrecta de la infiltrada</div>
			<div class="bg-incorrect p-2" style="width: 150px;">
				<img width="18" src=${handWrong} class="ms-1 me-2" />
				<span class="bold">${-10}</span> puntos
			</div>
		</div>
		</div>
	</div>`;

const QUIZ_ID = QUIZZES.SCENARIO5_2;
const SCENARIO_ID = SCENARIOS.SCENARIO_5;
const NAVIGATE_TO = ROUTES.SCENARIO5_PART2_QUESTION2;

const CORRECT_ANSWER_POINTS = 40;

function Scenario5Part2({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const participant = useSelector((state) => state.group);

	const scenarioNum = getScenarioByScreen(location.pathname);

	const [points, setPoints] = useState({ score: 0 });
	const [time, setTime] = useState(0);

	const [intentos, setIntentos] = useState(1);
	const [isCompleted, setIsCompleted] = useState(false);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "La probabilidad de alcanzar respuesta renal completa con el tratamiento con voclosporina fue 2,05 veces mayores que con el placebo.",
			explanation:
				"La probabilidad de alcanzar dicha RRC fueron 2,65 veces mayores con voclosporina.",
			selected: null,
			correct: false,
		},
		{
			id: 2,
			text: "Voclosporina reduce la proteinuria el doble de rápido que el brazo control/estándar de tratamiento actual.",
			selected: null,
			correct: true,
		},
		{
			id: 3,
			text: "El tiempo necesario para lograr una reducción del 50 % en la UPCR con voclosporina fue de 42 días.",
			explanation:
				"Con voclosporina se consiguió la reducción del 50 % de la UPCR en 29 días.",
			selected: null,
			correct: false,
		},
		{
			id: 4,
			text: "Al inicio del estudio se administró a los pacientes 0,5 g/día de metilprednisolona i. v. La dosis de glucocorticoides se fue reduciendo gradualmente de manera que, desde la semana 16 hasta el final del estudio, se administraron 2,5 mg/día de prednisona oral.",
			selected: null,
			correct: true,
		},
	]);

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		function handleOptionClick(newState) {
			if (isCompleted) return;

			let updatedOptions = options.map((x) =>
				x.id === id ? { ...x, selected: newState } : { ...x }
			);

			setOptions(updatedOptions);
		}

		function getTrueIcon() {
			if (isCompleted) {
				if (option?.selected === option?.correct && option?.correct)
					return iconTrueCorrect;
				else if (
					option?.selected !== option?.correct &&
					option?.correct
				)
					return iconTrueWrong;
				else return iconTrue;
			} else if (option?.selected) return iconTrueSelected;
			else return iconTrue;
		}

		function getFalseIcon() {
			if (isCompleted) {
				if (
					option?.selected === option?.correct &&
					option?.correct === false
				)
					return iconFalseCorrect;
				else if (
					option?.selected !== option?.correct &&
					option?.correct === false
				)
					return iconFalseWrong;
				else return iconFalse;
			} else if (option?.selected === false) return iconFalseSelected;
			else return iconFalse;
		}

		return (
			<div className="d-flex align-items-center justify-content-center mt-3">
				<div
					className="d-flex align-items-center justify-content-center txt-dark-green p-2"
					style={{
						position: "relative",
						minHeight: "10vh",
						height: "fit-content",
						borderRadius: "10px",
						backgroundColor: "#ffffff",
					}}
				>
					<div className="d-flex align-items-start">
						<div>{option?.id}.</div>
						<div className="ms-2">
							{option?.text}
							{isCompleted && (
								<div className="bold">
									{option?.explanation}
								</div>
							)}
						</div>
					</div>
				</div>
				<img
					className="ms-3"
					onClick={() => handleOptionClick(true)}
					src={getTrueIcon()}
				/>
				<img
					className="ms-2"
					onClick={() => handleOptionClick(false)}
					src={getFalseIcon()}
				/>
			</div>
		);
	};

	async function submitAnswer({ correct }) {
		setIsCompleted(true);
		const score = correct && intentos <= 3 ? CORRECT_ANSWER_POINTS : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateScore(score, SCORE_TYPES.SCORE));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						lastVisited: NAVIGATE_TO,
					});
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		if (options?.some((x) => x?.selected === null)) {
			alertError({
				error: "Debes responder todas las afirmaciones para poder continuar.",
			});
			return;
		}
		const wrongAnswers = options?.filter((x) => x?.selected !== x?.correct);
		const correct = wrongAnswers?.length === 0;

		const text = `<div class="text-center txt-black">
		<div class="txt-turquoise bold">
			${
				correct && intentos < 3
					? "¡Has acertado todas las afirmaciones!"
					: `Has acertado ${
							options?.length - wrongAnswers?.length
					  } afirmaciones.`
			} 
		</div>
		<div class="d-flex justify-content-center my-3">
			${
				correct && intentos < 3
					? `<div class="bg-correct txt-black w-fit-content p-2 bold">
					<img width="25" src=${handGood} class="me-1" />
					+${CORRECT_ANSWER_POINTS} puntos
				</div>`
					: ""
			}
		</div>
		<div>
			${
				correct && intentos < 3
					? "Pulsa el botón AVANZAR para ver la justificación"
					: intentos < 3
					? `Inténtalo de nuevo.\nTe quedan ${
							3 - intentos
					  } intento(s) para poder ganar los ${CORRECT_ANSWER_POINTS} puntos.`
					: "Se han acabado los intentos.\nPulsa el botón AVANZAR para ver la justificación"
			}
		</div>
	</div>`;

		setIntentos(intentos + 1);

		modalGenericSmall({ html: text }).then((res) => {
			if (res.isConfirmed) {
				if (correct || intentos === 3)
					submitAnswer({ correct: correct });
			}
		});
	}

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					{!isCompleted && (
						<div className="d-flex justify-content-start align-items-center">
							<img className="me-2" src={hand} />
							<div className="txt-white">
								Indica si estas afirmaciones relacionadas con el
								estudio Aurora<sup>1</sup> son verdaderas (V) o
								falsas (F):
							</div>
						</div>
					)}
					<div className="row d-flex justify-content-center align-items-start mt-2">
						<div className="col-2">
							<div
								className="txt8 txt-white"
								style={{ lineHeight: "15px" }}
							>
								<sup>1</sup>Rovin BH, Onno Teng YK, Ginzler EM,
								et al. Efficacy and safety of voclosporin versus
								placebo for lupus nephritis (AURORA 1): a
								double-blind, randomised, multicentre,
								placebo-controlled, phase 3 trial. Lancet.
								2021;397:2070-80.
							</div>
						</div>
						<div className="col-10 pt-2">
							{options.map((option) => {
								return (
									<div>
										<SelectOption id={option.id} />
									</div>
								);
							})}
						</div>
						<div className="txtWhite d-flex justify-content-center mt-3 txt12">
							RRC: respuesta renal completa <br />
							UPCR: cociente proteína/creatinina en orina
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					right: "0",
					bottom: "13vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					{!isCompleted ? (
						<>
							<div
								className="btn-main"
								onClick={() => checkAnswers()}
							>
								<div className="text-btn-main">AVANZAR</div>
							</div>
						</>
					) : (
						<div
							className="btn-main"
							onClick={() =>
								modalQuestionInfo({ html: QUESTION_INFO }).then(
									(res) => {
										if (res.isConfirmed)
											navigate(
												ROUTES.SCENARIO5_PART2_QUESTION2
											);
									}
								)
							}
						>
							<div className="text-btn-main">AVANZAR</div>
						</div>
					)}
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario5Part2);
