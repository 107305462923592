import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import iconCorrect from "../../assets/icon-correct.png";
import mano from "../../assets/mano.png";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateScore, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertError,
	modalConfirm,
	modalQuestion1Feedback,
} from "../../utils/logger";

const QUIZ_ID = QUIZZES.SCENARIO1;
const SCENARIO_ID = SCENARIOS.SCENARIO_1;
const NAVIGATE_TO = ROUTES.TRANSITION_SCENARIOS;

function Scenario1({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);
	const scenarioNum = getScenarioByScreen(location.pathname);

	const [isCompleted, setIsCompleted] = useState(false);

	const CORRECT_ANSWER_POINTS = 10;
	const WRONG_ANSWER_POINTS = -20;

	const [points, setPoints] = useState(0);
	const [penalty, setPenalty] = useState(0);

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "Pruebas de función renal",
			selected: false,
			correct: true,
			checked: false,
			explanation: `<div>
					<div>
						<span class="bold">Esta prueba está justificada.</span>
						He aquí los resultados:
					</div>
					<div class="d-flex justify-content-center mt-3">
					<div
						class="p-3 w-fit-content text-left"
						style="background-color: #F2F2F2; border-radius: 10px;"
					>
						<p class="bold">Creatinina: 0,73 mg/dl</p>
						<p class="bold">
							FG >90 ml/min/1,73 m
							<sup>2</sup>
						</p>
						<p>Urea: 32 mg/dl</p>
						<p>Urato: 3,3 mg/dl</p>
						<p>UPCR: 11 370 mg/g</p>
						<p>Proteínas: 8,3 mg/dl</p>
						<p>Albúmina: 3,5 mg/dl</p>
						<p>Ionograma normal</p>
						<p>PCR: 6,9 mg/l</p>
					</div>
					</div>
				</div>`,
		},
		{
			id: 2,
			text: "Pruebas de autoinmunidad",
			selected: false,
			correct: true,
			checked: false,
			confirmExplanation: `<div class="text-left">
					<div>
						Las pruebas de autoinmunidad que vas a solicitar son:
					</div>
					<ul class="mt-2">
						<li class="bold">ANA</li>
						<li class="mt-2 bold">Anti-DNA</li>
						<li class="mt-2 bold">
							Anemia ferropénica, ferroterapia oral irregular.
						</li>
						<li class="mt-2 bold">Complemento</li>
						<li class="mt-2 bold">ENAS </li>
					</ul>
					<div>
						¿Confirmas que quieres solicitar esta prueba?
					</div>
				</div>`,
			explanation: `<div>
					<div>
						<span class="bold">Esta prueba está justificada.</span>
						He aquí el resultado:
					</div>
					<div class="d-flex justify-content-center mt-3">
						<div
							class="p-3 w-fit-content text-left"
							style="background-color: #F2F2F2; border-radius: 10px;"
						>
							<p class="bold">
								ANA +, patrón granular grueso, título >1:80
							</p>
							<p class="bold">Anti-DNA + 48 IU/ml (0-35)</p>
							<p>
								Resto de especificidades negativas: ANCA
								negativos, anti-MBG negativos, anticuerpos
								anticardiolipina y anticuerpos anti-b2
								glicoproteína negativos
							</p>
							<p>Anticoagulante lúpico negativo</p>
							<p>
								C3: 32 mg/dl (rango de normalidad: 70-140 mg/dl)
							</p>
							<p class="bold">
								C4: 9,3 mg/dl (rango de normalidad: 15-30 mg/dl)
							</p>
						</div>
						<div class="p-3 w-fit-content text-left">
							<strong>ANA:</strong> anticuerpos antinucleares; <strong>Anti-DNA:</strong> anticuerpos anti-DNA; <strong>ANCA:</strong> anticuerpos anticitoplasma de nutrófilos;
						</div>
					</div>
				</div>`,
		},
		{
			id: 3,
			text: "Urografía por RM",
			selected: false,
			correct: false,
			checked: false,
			explanation: `<div>
					<div class="bold">Esta prueba no está justificada.</div>
					<div class="d-flex justify-content-center mt-3 text-center">
						<p>
							Dado que no hay signos de cólicos
							renoureterales, infecciones del tracto urinario
							o evidencia de obstrucción urinaria en la
							información clínica, solicitar una urografía por
							RM (o TC) podría considerarse innecesario en
							este momento.
						</p>
					</div>
				</div>`,
		},
		{
			id: 4,
			text: "TAC abdominal",
			selected: false,
			correct: false,
			checked: false,
			explanation: `<div>
					<div class="bold">Esta prueba no está justificada.</div>
					<div class="d-flex justify-content-center mt-3 text-center">
						<p>
							Dado que no hay evidencia actual de
							complicaciones nefrológicas graves, como
							obstrucción, lesiones quísticas o estructurales,
							una TAC abdominal no sería prioritaria en este
							momento.
						</p>
					</div>
				</div>`,
		},
		{
			id: 5,
			text: "Proteinograma sérico",
			selected: false,
			correct: true,
			checked: false,
			explanation: `<div>
			<div>
				<span class="bold">Esta prueba está justificada.</span>
				He aquí el resultado:
			</div>
			<div class="d-flex justify-content-center mt-3">
			<div
				class="p-3 w-fit-content text-left"
				style="background-color: #F2F2F2; border-radius: 10px;"
			>				
				<p>Proteinograma sérico sin pico monoclonal</p>
				<p>Inmunoglobulinas normales, salvo IgM: 1860 mg/dl (rango de normalidad <1600 mg/dl)</div>
			</div>
			</div>
		</div>`,
		},
		{
			id: 6,
			text: "Biopsia renal",
			selected: false,
			correct: true,
			checked: false,
			explanation: `<div>
					<div>
						<span class="bold">Esta prueba está justificada.</span>
						He aquí los resultados:
					</div>
					<div class="d-flex justify-content-center mt-3">
						<div
							class="p-3 w-fit-content h-fit-content text-center"
							style="background-color: #F2F2F2; border-radius: 10px;"
						>
							<div>
								¡Vaya! Parece que el informe se ha traspapelado.
								El responsable de documentación asegura que lo
								va a buscar y que te lo hará llegar en cuanto lo
								encuentre.
							</div>
						</div>
					</div>
				</div>`,
		},
		{
			id: 7,
			text: "Serología VIH y hepatitis",
			selected: false,
			correct: true,
			checked: false,
			explanation: `<div>
					<div>
						<span class="bold">Esta prueba está justificada.</span>
						He aquí los resultados:
					</div>
					<div class="d-flex justify-content-center mt-3">
					<div
						class="p-3 w-fit-content text-left"
						style="background-color: #F2F2F2; border-radius: 10px;"
					>
						<p>VIH, VHC negativas</p>
						<p>VHB inmunizada</p>						
					</div>
					<div class="p-3 w-fit-content text-left">
						<strong>VHB:</strong> virus de la hepatitis B; <strong>VIH:</strong> virus de la inmunodeficiencia humana;
					</div>
					</div>
				</div>`,
		},
		{
			id: 8,
			text: "Pruebas genéticas específicas para enfermedades renales hereditarias",
			selected: false,
			correct: false,
			checked: false,
			explanation: `<div>
					<div class="bold">Esta prueba no está justificada.</div>
					<div class="d-flex justify-content-center mt-3 text-center">
						<p>
							Dado que no hay indicios específicos de una
							enfermedad renal hereditaria en la información
							proporcionada, pruebas genéticas para estas
							condiciones no serían necesarias en este
							momento.
						</p>
					</div>
				</div>`,
		},
		{
			id: 9,
			text: "Sedimento y proteinuria en 24 h",
			selected: false,
			correct: true,
			checked: false,
			explanation: `<div>
					<div>
						<span class="bold">Esta prueba está justificada.</span>
						He aquí el resultado:
					</div>
					<div class="d-flex justify-content-center mt-3">
						<div
							class="p-3 w-fit-content text-left"
							style="background-color: #F2F2F2; border-radius: 10px;"
						>
							<p>Proteinograma sérico sin pico monoclonal</p>
							<p class="bold">Leucocitos: 30-50/campo</p>
							<p class="bold">Hematíes: 50-100/campo</p>
							<p>Células epiteliales escasas</p>
							<p>Albúmina/creatinina: 1871 mg/g</p>
							<p>Proteína/creatinina: 4130 mg/g</p>
							<p>Volumen total de orina en 24 h: 1300 cc</p>
							<p>
								<span class="bold">
									Proteínas: 3070 mg/24 h
								</span>
								(69 % albúmina)
							</p>
						</div>
					</div>
				</div>`,
		},
	]);

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		function handleOptionClick() {
			if (isCompleted || option?.checked) return;

			let updatedOptions = options.map((x) =>
				x.id === id ? { ...x, selected: true } : { ...x }
			);

			setOptions(updatedOptions);

			modalConfirm({
				text:
					!option?.confirmExplanation &&
					"¿Confirmas que quieres solicitar esta prueba?",
				htmlText:
					option?.confirmExplanation && option?.confirmExplanation,
			}).then((res) => {
				if (res.isConfirmed) {
					let updatedOptions = options.map((x) =>
						x.id === id
							? { ...x, selected: true, checked: true }
							: { ...x }
					);

					setOptions(updatedOptions);

					modalQuestion1Feedback({
						question: option?.text,
						answerHtml: option.explanation,
						correct: option?.correct,
						points: option?.correct
							? CORRECT_ANSWER_POINTS
							: WRONG_ANSWER_POINTS,
					});

					if (option?.correct)
						setPoints(points + CORRECT_ANSWER_POINTS);
					else {
						setPenalty(penalty + WRONG_ANSWER_POINTS);
						setPoints(points + WRONG_ANSWER_POINTS);
					}

					if (!updatedOptions?.some((x) => x.correct && !x.selected))
						setIsCompleted(true);
				} else {
					let updatedOptions = options.map((x) =>
						x.id === id ? { ...x, selected: false } : { ...x }
					);

					setOptions(updatedOptions);
				}
			});
		}

		function getBackgroundColor() {
			return option?.selected && option?.correct && option?.checked
				? "#D6D431"
				: option?.selected && !option?.correct && option?.checked
				? "#FF5948"
				: option?.selected
				? "#EAF886"
				: "#FFFFFF";
		}

		return (
			<div>
				<div
					className={`d-flex align-items-center justify-content-center txt-turquoise text-center mt-3 ${
						option?.selected ? "bold option-selected" : ""
					}`}
					style={{
						position: "relative",
						height: "15vh",
						borderRadius: "10px",
						backgroundColor: getBackgroundColor(),
					}}
					onClick={handleOptionClick}
				>
					{option?.text}
					{option?.checked && option?.correct && (
						<img
							style={{ position: "absolute", top: 10, right: 10 }}
							width={20}
							src={iconCorrect}
						/>
					)}
				</div>
			</div>
		);
	};

	async function submitAnswer() {
		if (options?.some((x) => x.correct && !x.selected)) {
			alertError({
				error: " ",
				customMessage:
					"Para avanzar, debes escoger las 6 pruebas justificadas.",
			});
			return;
		}

		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: participant?._id,
			score: points,
			time: time,
			correct: true,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateScore(points, SCORE_TYPES.SCORE));
					dispatch(updateScore(penalty, SCORE_TYPES.PENALTY));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + points,
						penalty: participant?.penalty + penalty,
						lastVisited: NAVIGATE_TO,
					});
					navigate(NAVIGATE_TO, {
						state: { activeScenario: scenarioNum + 1 },
					});
				}
			})
			.catch((error) => {});
	}

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					<div className="col-12">
						<div className="d-flex justify-content-center align-items-center">
							<img src={mano} />
							<div className="ms-2 txtWhite">
								Has seleccionado{" "}
								{
									options?.filter(
										(x) =>
											x.selected && x.correct && x.checked
									)?.length
								}{" "}
								de 6 pruebas
							</div>
						</div>
					</div>
					{options.map((option) => {
						return (
							<div className="col-4">
								<SelectOption id={option.id} />
							</div>
						);
					})}
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "13vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					<div className="btn-main" onClick={() => submitAnswer()}>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario1);
