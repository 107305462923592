import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import handGood from "../../assets/hand-good.png";
import handWrong from "../../assets/hand-wrong.png";
import logoMedical from "../../assets/logo-medical.png";
import esquema from "../../assets/scenario5-esquema4.png";

import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen, handleNavigate } from "../../utils/helpers";
import { modalGeneric } from "../../utils/logger";

function Scenario5Part2Intro2({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const group = useSelector((state) => state.group);

	const EXPLANATION = `<div class="txt-black text-center txt14">
			<div class="txt-turquoise txt16 bold">
				El estudio Aurora 1<sup>1</sup> es un ensayo clínico aleatorizado,
				multicéntrico, controlado por placebo y doble ciego, N = 357,
				cuya variable principal fue respuesta renal completa a la semana
				52 con n=357 pacientes con nefritis lúpica.
			</div>
			<div class="mt-4">
				A continuación, encontrarás una batería de 4 afirmaciones.
			</div>
			<div class="bold mt-3">¿Son verdaderas o falsas?</div>
			<div class="row mt-4 d-flex align-items-center px-5">
				<div class="col-6 text-left">
					<div>Acertarlas todas en los 3 primeros intentos:</div>
				</div>
				<div class="col-6 d-flex justify-content-end">
					<div class="py-2 w-50 bg-correct txt-black bold d-flex align-items-center justify-content-center">
						<img width="25" src=${handGood} class="me-2" />
						<div>+40 puntos</div>
					</div>
				</div>
			</div>
			<div class="row mt-2 d-flex align-items-center px-5">
				<div class="col-6 text-left">
					<div>En otro caso:</div>
				</div>
				<div class="col-6 d-flex justify-content-end">
					<div class="py-2 w-50 bg-incorrect txt-black bold d-flex align-items-center justify-content-center">
						<img width="20" src=${handWrong} class="me-2" />
						<div>0 puntos</div>
					</div>
				</div>
			</div>
			<div class="txt8 mt-5 text-left" style="line-height: 15px">
				<sup>1</sup> Rovin BH, Onno Teng YK, Ginzler EM, et al. Efficacy and safety
				of voclosporin versus placebo for lupus nephritis (AURORA 1): a
				double-blind, randomised, multicentre, placebo-controlled, phase
				3 trial. Lancet. 2021;397:2070-80. 
			</div>
		</div>`;

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					style={{ paddingLeft: "6vh" }}
					className="row d-flex justify-content-start align-items-center pt-5"
				>
					<div className="col-4 txtWhite mb-5">
						<div
							className="txtWhite txt20 bold mt-3"
							style={{ letterSpacing: 3 }}
						>
							Uno de los fármacos que puedes introducir para la
							triple terapia es{" "}
							<span className="txt-yellow-light">
								voclosporina
							</span>
							.
						</div>
						<div
							className="bold txt-yellow-light mt-3"
							style={{ lineHeight: "20px" }}
						>
							¿Has oído hablar de este nuevo inhibidor de la
							calcineurina? ¿Y del estudio Aurora 1?
						</div>
						<div
							className="txt8 txt-white mt-5 pt-4"
							style={{ lineHeight: "15px" }}
						>
							Figura adaptada de Huizinga et al. The 12th
							International Congress on Systemic Lupus
							Erythematosus (LUPUS 2017) & the 7th Asian Congress
							on Autoimmunity (ACA 2017).
						</div>
					</div>
					<div className="col-8">
						<img width={"95%"} src={esquema} />
						<div
							className="txt8 txt-white px-5 mt-4"
							style={{ lineHeight: "15px" }}
						>
							<sup>1</sup>Busque S et al. Am J Transplant
							2011;11:2675–84. Rovin BH et al. Data presented at
							American Society of Nephrology Kidney Week 2020
							Reimagined virtual conference, 19–25 October 2020.
							Kuglstatter A et al. Acta Crystallogr D Biol
							Crystallogr. 2011;67:119‒23. Van Gelder T et al.
							Expert Rev Clin Pharmacol. 2022;15:515‒29.
						</div>
					</div>
					<div
						className="container"
						style={{
							position: "absolute",
							right: "7vh",
							bottom: "7vh",
						}}
					>
						<div className="d-flex justify-content-end">
							<div
								onClick={() =>
									modalGeneric({ html: EXPLANATION }).then(
										(res) => {
											if (res.isConfirmed)
												handleNavigate({
													group: group,
													navigate: navigate,
													dispatch: dispatch,
													navigateTo:
														ROUTES.SCENARIO5_PART2,
													scenarioNum:
														getScenarioByScreen(
															ROUTES.SCENARIO5_PART2
														),
												});
										}
									)
								}
								className="btn-main"
							>
								<div className="text-btn-main">AVANZAR</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario5Part2Intro2);
