import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMedical from "../../assets/logo-medical.png";
import treatment from "../../assets/treatment.png";
import NextButton from "../../components/NextButton";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function Scenario6End({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const scenarioNum = getScenarioByScreen(location.pathname);

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5">
					<div className="col-8">
						<div className="d-flex flex-column align-items-center">
							<div className="txt18 bold txt-white mt-5 text-center">
								Al inicio del tratamiento, Martina tenía un TFGe
								de 90 ml/min/1,73 m<sup>2</sup> y en la última analítica
								era de 59. Su reducción ha sido del 34,4 %.
							</div>
							<div className="text-center txt-white my-4 py-3">
								Por lo tanto, hay que:
							</div>
							<div
								className="p-4 bg-yellow option-selected w-75"
								style={{ borderRadius: "10px" }}
							>
								<div className="d-flex align-items-start justify-content-start">
									<div>1.</div>
									<div className="ms-2">
										Suspender temporalmente la
										administración de voclosporina. 
									</div>
								</div>
								<div className="d-flex align-items-start justify-content-start mt-2">
									<div>2.</div>
									<div className="ms-2">
										Reiniciar el tratamiento tras la
										recuperación de la TFGe con una dosis de
										7,9 mg (1 cápsula) 2 veces al día y
										aumentar la dosis según la tolerabilidad
										de la función renal  
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton
						navigateTo={ROUTES.TRANSITION_SCENARIOS}
						activeScenario={scenarioNum + 1}
					/>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario6End);
