import React, { useRef } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import handGood from "../../assets/hand-good.png";
import handWrong from "../../assets/hand-wrong.png";
import historicalTable from "../../assets/historico-analiticas.png";
import logoMedical from "../../assets/logo-medical.png";
import mano from "../../assets/mano.png";
import mensaje1 from "../../assets/mensaje-1.png";
import mensaje10 from "../../assets/mensaje-10.png";
import mensaje11 from "../../assets/mensaje-11.png";
import mensaje12 from "../../assets/mensaje-12.png";
import mensaje13 from "../../assets/mensaje-13.png";
import mensaje14 from "../../assets/mensaje-14.png";
import mensaje2 from "../../assets/mensaje-2.png";
import mensaje3 from "../../assets/mensaje-3.png";
import mensaje4 from "../../assets/mensaje-4.png";
import mensaje5 from "../../assets/mensaje-5.png";
import mensaje6 from "../../assets/mensaje-6.png";
import mensaje7 from "../../assets/mensaje-7.png";
import mensaje8 from "../../assets/mensaje-8.png";
import mensaje9 from "../../assets/mensaje-9.png";
import reset from "../../assets/reset.png";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen, handleNavigate } from "../../utils/helpers";
import { modalGeneric, modalQuestionInfo } from "../../utils/logger";

const EXPLORATION_HTML = `<div class="d-flex flex-column align-items-start justify-content-center">
<div class="txt-turquoise bold txt20">
	Realizas una exploración física a la paciente.
</div>
<div class="my-3">Estos son los resultados:</div>
<ul>
	<li>Altura: 165 cm, peso: 61 kg</li>
	<li>PA en consulta externa: 142/92 mmHg</li>
	<li>FC: 78 lpm </li>
	<li>
		Afebril, buen estado general. Consciente y orientada,
		colaboradora. Normocoloreada y normohidratada.
	</li>
	<li>Torácica cerrada y resonante, sin soplos.</li>
	<li>Normoperfundida, edema ++ pretibial.</li>
	<li>No lesiones cutáneas.</li>
	<li>
		Eupneica en reposo, movimientos coordinados voluntarios, sin
		ruidos sobreañadidos.
	</li>
	<li>
		Sin focalidad neurológica ni <i>flapping</i>.
	</li>
</ul>
</div>`;

const HISTORICAL_TABLE_HTML = `<div class="d-flex flex-column align-items-center justify-content-center text-center">
<div class="txt-turquoise bold txt20">
	Finalmente, entre la documentación observas que hay un histórico
	de análisis.
</div>
<div class="my-2">Échale un vistazo por si acaso…</div>			
<img width="100%" src=${historicalTable}></img>
</div>`;

const QUESTION_INFO = `<div class="d-flex flex-column align-items-start justify-content-center">
<div class="txt-turquoise bold txt20 text-center">
	Tras revisar toda la información relacionada con esta paciente,
	¿qué pruebas solicitarías?
</div>
<div class="d-flex align-items-start mt-4">
	<img width="10%" src=${mano} />
	<ul>
		<li>
			En la siguiente batería de pruebas, hay 3 que no se
			justifican, y 6 que pueden ser justificables. Selecciona
			solo las 6 pruebas justificables y lee con atención los
			resultados.
		</li>
		<li class="mt-2">
			Avanzarás cuando hayas seleccionado las 6 pruebas
			correctas.
		</li>
	</ul>
</div>
<div class="d-flex align-items-center justify-content-between w-75 ms-5 mt-3">
	<div class="">Cada prueba justificable:</div>
	<div class="bg-correct p-2" style="width: 145px;">
		<img width="25" src=${handGood} class="me-1" />
		+10 puntos
	</div>
</div>
<div class="d-flex align-items-center justify-content-between w-75 mt-3 ms-5">
	<div class="">Cada prueba injustificada:</div>
	<div class="bg-incorrect p-2" style="width: 145px;">
		<img width="20" src=${handWrong} class="me-2" />
		-20 puntos
	</div>
</div>
</div>`;

function Scenario1Conversation({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const location = useLocation();

	const group = useSelector((state) => state.group);

	const lastMessageRef = useRef(null);

	const scrollToBottom = () => {
		lastMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5">
					<div
						className="col-9"
						style={{ height: "60vh", overflowY: "scroll" }}
					>
						<div className="d-flex justify-content-start">
							<img width={607} src={mensaje1} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={538} src={mensaje2} />
						</div>
						<div className="d-flex justify-content-start">
							<img width={314} src={mensaje3} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={491} src={mensaje4} />
						</div>
						<div className="d-flex justify-content-start">
							<img width={204} src={mensaje5} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={221} src={mensaje6} />
						</div>
						<div className="d-flex justify-content-start">
							<img width={564} src={mensaje7} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={538} src={mensaje8} />
						</div>
						<div className="d-flex justify-content-start">
							<img width={534} src={mensaje9} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={221} src={mensaje10} />
						</div>
						<div className="d-flex justify-content-start">
							<img width={514} src={mensaje11} />
						</div>
						<div className="d-flex justify-content-end">
							<img width={221} src={mensaje12} />
						</div>
						<div className="d-flex justify-content-start">
							<img width={394} src={mensaje13} />
						</div>
						<div
							className="d-flex justify-content-end"
							ref={lastMessageRef}
						>
							<img width={371} src={mensaje14} />
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<div
						className="btn-main-cancel me-3"
						onClick={(e) => scrollToBottom()}
					>
						<img height={"40%"} src={reset} />
						<div className="text-btn-main ms-2">
							Ver conservación
						</div>
					</div>
					<div
						className="btn-main"
						onClick={(e) => {
							modalGeneric({ html: EXPLORATION_HTML }).then(
								(res) => {
									if (res.isConfirmed)
										modalGeneric({
											html: HISTORICAL_TABLE_HTML,
										}).then((res) => {
											if (res.isConfirmed) {
												modalQuestionInfo({
													html: QUESTION_INFO,
												}).then((res) => {
													if (res.isConfirmed) {
														handleNavigate({
															group: group,
															navigate: navigate,
															dispatch: dispatch,
															navigateTo:
																ROUTES.SCENARIO1,
															scenarioNum:
																getScenarioByScreen(
																	ROUTES.SCENARIO1
																),
														});
													}
												});
											}
										});
								}
							);
						}}
					>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario1Conversation);
