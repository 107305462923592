import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateScore, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import { alertError, modalQuestion5Feedback } from "../../utils/logger";

const CORRECT_ANSWER_POINTS = 20;
const WRONG_ANSWER_POINTS = -10;

const QUIZ_ID = QUIZZES.SCENARIO5;
const SCENARIO_ID = SCENARIOS.SCENARIO_5;
const NAVIGATE_TO = ROUTES.SCENARIO5_RESULT;

function Scenario5({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);
	const scenarioNum = getScenarioByScreen(location.pathname);

	const [isCompleted, setIsCompleted] = useState(false);

	const [points, setPoints] = useState({ score: 0, penalty: 0 });
	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "a)",
			selected: false,
			correct: false,
			content: (
				<div>
					<div>
						<span className="bold">Función renal (eGFR):</span>
						normal o ≤40 % inferior al valor prebrote.
					</div>
					<div>
						<span className="bold">Proteinuria (g/24 h, g/g):</span>
						Reducción &gt;20 %.
					</div>
					<div>
						<span className="bold">Sedimento urinario:</span> &lt;15
						hematíes/campo.
					</div>
					<div>
						<span className="bold">
							Actividad inmunológica (anti-dsDNA, C3/C4) :
						</span>
						descenso progresivo.
					</div>
				</div>
			),
		},
		{
			id: 3,
			text: "c)",
			selected: false,
			correct: false,
			content: (
				<div>
					<div>
						<span className="bold">Función renal (eGFR):</span>{" "}
						normal o ≤40 % inferior al valor prebrote.
					</div>
					<div>
						<span className="bold">Proteinuria (g/24 h, g/g):</span>{" "}
						Reducción &gt;25 %.
					</div>
					<div>
						<span className="bold">Sedimento urinario:</span> &lt;15
						hematíes/campo.
					</div>
					<div>
						<span className="bold">
							Actividad inmunológica (anti-dsDNA, C3/C4):
						</span>{" "}
						actividad baja o ausente.
					</div>
				</div>
			),
		},
		{
			id: 2,
			text: "b)",
			selected: false,
			correct: false,
			content: (
				<div>
					<div>
						<span className="bold">Función renal (eGFR):</span>{" "}
						normal o ≤20 % inferior.
					</div>
					<div>
						<span className="bold">Proteinuria (g/24 h, g/g):</span>{" "}
						respuesta parcial o completa.
					</div>
					<div>
						<span className="bold">Sedimento urinario:</span> &lt;15
						hematíes/campo o inactivo.
					</div>
					<div>
						<span className="bold">
							Actividad inmunológica (anti-dsDNA, C3/C4):
						</span>{" "}
						descenso progresivo.
					</div>
				</div>
			),
		},
		{
			id: 4,
			text: "d)",
			selected: false,
			correct: true,
			content: (
				<div>
					<div>
						<span className="bold">Función renal (eGFR):</span>{" "}
						normal o ≤50 % inferior al valor prebrote.
					</div>
					<div>
						<span className="bold">Proteinuria (g/24 h, g/g):</span>{" "}
						Reducción &gt;25 %.
					</div>
					<div>
						<span className="bold">Sedimento urinario:</span> &lt;15
						hematíes/campo.
					</div>
					<div>
						<span className="bold">
							Actividad inmunológica (anti-dsDNA, C3/C4):
						</span>{" "}
						descenso progresivo de la actividad inmunológica.
					</div>
				</div>
			),
		},
	]);

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		function handleOptionClick() {
			if (isCompleted) return;

			let updatedOptions = options.map((x) =>
				x.id === id
					? { ...x, selected: !x?.selected }
					: { ...x, selected: false }
			);

			setOptions(updatedOptions);
		}

		function getBackgroundColor() {
			return option?.selected && option?.correct && isCompleted
				? "#D6D431"
				: option?.selected && !option?.correct && isCompleted
				? "#FF5948"
				: option?.selected
				? "#EAF886"
				: "#FFFFFF";
		}

		return (
			<div>
				<div
					className={`d-flex flex-column align-items-center justify-content-center txt-dark-green mt-3 px-2 ${
						option?.selected ? "option-selected" : ""
					}`}
					style={{
						height: "18vh",
						lineHeight: "20px",
						borderRadius: "10px",
						backgroundColor: getBackgroundColor(),
					}}
					onClick={handleOptionClick}
				>
					<div className="row">
						<div className="col-1">
							<div className="bold">{option?.text}</div>
						</div>
						<div className="col-11 ps-0">{option?.content}</div>
					</div>
				</div>
			</div>
		);
	};

	function checkAnswers() {
		if (!options?.some((x) => x.selected)) {
			alertError({
				error: " ",
				customMessage:
					"Para avanzar, debes escoger al menos una opción.",
			});
			return;
		}

		const correct = options?.some((x) => x.selected && x.correct);

		setIsCompleted(correct);

		const score = correct ? CORRECT_ANSWER_POINTS : WRONG_ANSWER_POINTS;

		setPoints({
			...points,
			score: points?.score + score,
			penalty: points?.penalty + WRONG_ANSWER_POINTS,
		});

		const text = correct
			? `<div class="text-center">
					<div class="bold txt-turquoise">¡Correcto!</div>
					<div class="txt-black my-4">
						Estos son los criterios del documento de consenso GLOSEN
						2023<sup>1</sup> para la fase inicial o de inducción de debut o
						recaída de nefritis lúpica:
					</div>
				</div>`
			: `<div class="text-center">
					<div class="bold txt-turquoise">
						No es la respuesta correcta.
					</div>
					<div class="txt-black my-3">Vuelve a intentarlo.</div>
				</div>`;

		modalQuestion5Feedback({
			text: text,
			correct: correct,
			points: score,
		});
	}

	function submitAnswer() {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: participant?._id,
			score: points.score,
			time: time,
			correct: true,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateScore(points.score, SCORE_TYPES.SCORE));
					dispatch(updateScore(points.penalty, SCORE_TYPES.PENALTY));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + points.score,
						penaltyScore:
							participant?.penaltyScore + points.penalty,
						lastVisited: NAVIGATE_TO,
					});
					navigate(NAVIGATE_TO, {
						state: { activeScenario: scenarioNum + 1 },
					});
				}
			})
			.catch((error) => {});
	}

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					<div className="d-flex justify-content-center align-items-center my-4">
						<div className="col-11 text-center">
							<div className="txtWhite txt20 bold">
								¿Qué criterios a los tres meses propone el
								documento de consenso GLOSEN 2023 para valorar
								la efectividad de un tratamiento de debut o
								recaída de nefritis lúpica?
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-center align-items-center">
						<div className="row col-12">
							{options.map((option) => {
								return (
									<div className="col-6">
										<SelectOption id={option.id} />
									</div>
								);
							})}
							<div
								className="txt8 txt-white w-50 mt-3"
								style={{ lineHeight: "15px" }}
							>
								Adaptado de 1 Rojas-Rivera JE, García-Carro C,
								Ávila A, et al. Documento de consenso del Grupo
								de Estudio de Enfermedades Glomerulares de la
								Sociedad Española de Nefrología (GLOSEN) para el
								diagnóstico y tratamiento de la nefritis lúpica.
								Nefrología. 2023;43:6-47<br/>
								<strong>eGFR:</strong> tasa de filtración glomerular estimada
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "13vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					<div
						className="btn-main"
						onClick={() => {
							if (isCompleted) submitAnswer();
							else checkAnswers();
						}}
					>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario5);
