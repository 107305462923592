import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import camera from "../assets/camera.png";
import logoMedical from "../assets/logo-medical.png";
import introVideo from "../assets/videos/intro-video.mp4";
import { ROUTES } from "../utils/constants";
import { handleNavigate } from "../utils/helpers";

function Video({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const group = useSelector((state) => state.group);

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas d-flex justify-content-center">
				<div
					style={{
						left: 0,
						position: "absolute",
						paddingTop: "6vh",
						paddingLeft: "6vh",
					}}
				>
					<img width={240} src={logoMedical} />
				</div>
				<div
					className="bg-black w-100 txt-white"
					style={{
						zIndex: 10,
						height: "70vh",
						marginTop: "5vw",
						marginLeft: "7vw",
						marginRight: "7vw",
						borderRadius: "20px",
					}}
				>
					<div className="d-flex justify-content-end">
						<img width={60} className="pe-4 pt-4" src={camera} />
					</div>
					<div className="h-75 d-flex justify-content-center align-items-center txt18 text-center">
						<video
							style={{ marginTop: "10vh" }}
							src={introVideo}
							controls
							width="90%"
						/>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<div
						className="btn-main"
						onClick={(e) => {
							handleNavigate({
								group: group,
								navigate: navigate,
								dispatch: dispatch,
								navigateTo: ROUTES.CONTEXT,
							});
						}}
					>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Video);
