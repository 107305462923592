import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getGroupById } from "./api/group";
import "./App.css";
import TopBar from "./components/TopBar";
import "./customStyles/modals.css";
import { ROUTES } from "./utils/constants";
import { clearTimer, getGroupId, reconnect } from "./utils/helpers";

function App() {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(
		getGroupId() && ![ROUTES.HOME].includes(location.pathname)
	);

	const noTopBarScreens = [
		ROUTES.HOME,
		ROUTES.VIDEO,
		ROUTES.REGISTER,
		ROUTES.CONTEXT,
		ROUTES.TRANSITION_SCENARIOS,

		ROUTES.SCENARIO1_INTRO,
		ROUTES.SCENARIO1_CONVERSATION_INTRO,
		ROUTES.SCENARIO1_CONVERSATION,

		ROUTES.SCENARIO2_INTRO,
		ROUTES.SCENARIO2_RESULT,

		ROUTES.SCENARIO3_INTRO,
		ROUTES.SCENARIO3_RESULT,

		ROUTES.SCENARIO4_INTRO,

		ROUTES.SCENARIO5_INTRO,
		ROUTES.SCENARIO5_CONVERSATION,
		ROUTES.SCENARIO5_END,

		ROUTES.SCENARIO5_PART2_INTRO,
		ROUTES.SCENARIO5_PART2_INTRO2,
		ROUTES.SCENARIO5_PART2_END,

		ROUTES.SCENARIO6_INTRO,
		ROUTES.SCENARIO6_END,

		ROUTES.SCENARIO7_INTRO,
		ROUTES.SCENARIO7_END,

		ROUTES.SUPERTEST_INTRO,
		ROUTES.SUPERTEST_END,

		ROUTES.END,
		ROUTES.GAMEOVER,
	];
	const showTopBar = !noTopBarScreens.some(
		(x) => location.pathname.toString() === x
	);

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			const confirmationMessage =
				"¿Estás seguro de que quieres abandonar la página? Podrías perder información no guardada.";
			event.returnValue = confirmationMessage;
			return confirmationMessage;
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		let groupId = getGroupId();

		if (groupId) {
			if (![ROUTES.HOME].includes(location.pathname)) {
				setLoading(true);
				getGroupById(groupId)
					.then((res) => {
						if (res.status === 200) {
							reconnect({
								group: res.data,
								dispatch: dispatch,
								navigate: navigate,
							});
							setTimeout(() => {
								setLoading(false);
							}, 100);
						}
					})
					.catch((error) => {});
			}
		} else {
			clearTimer();
		}
	}, []); //TODO: if user is not allowed to go back -> add location.pathname dependency

	return loading ? (
		<>
			<div className="splash-screen">{showTopBar && <TopBar />}</div>
		</>
	) : (
		<div className="App">
			{showTopBar && <TopBar />}
			<Outlet />
		</div>
	);
}
export default App;
