import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMedical from "../../assets/logo-medical.png";
import martina from "../../assets/martina.png";
import NextButton from "../../components/NextButton";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function SupertestEnd({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const scenarioNum = getScenarioByScreen(location.pathname);

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5 pt-4">
					<div className="col-1"></div>
					<div className="col-4">
						<img width={350} src={martina} />
					</div>
					<div className="col-5">
						<div className="ps-5 txt16">
							<div
								className="txt20 bold txt-yellow-light mt-4"
								style={{ lineHeight: "30px" }}
							>
								Por cierto… ¿Qué fue de Martina?
							</div>
							<div className="txt-white mt-4">
								Martina siguió con su medicación hasta cumplir 3
								años de tratamiento. Dada la situación de
								remisión clínica e inmunológica, se decide
								iniciar descenso progresivo del tratamiento
								inmunosupresor y control prospectivo de la
								actividad del lupus y la función renal.
							</div>
						</div>
					</div>
					<div className="col-1"></div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton navigateTo={ROUTES.END} />
				</div>
			</div>
		</div>
	);
}

export default injectIntl(SupertestEnd);
