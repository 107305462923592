import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import handGood from "../../assets/hand-good.png";
import logoMedical from "../../assets/logo-medical.png";
import logoSupertest from "../../assets/logo-supertest.png";
import scenarioPassedBig from "../../assets/scenariobar/scenario-passed-big.png";
import NextButton from "../../components/NextButton";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function SupertestIntro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const scenarioNum = getScenarioByScreen(location.pathname);

	const scenarios = [
		<div>
			Pruebas
			<br />
			diagnósticas
		</div>,
		<div className="mb-2 pb-1">Diagnóstico</div>,
		<div>
			Diagnóstico
			<br />
			por imagen
		</div>,
		<div className="mb-2 pb-1">Tratamiento</div>,
		<div>
			Actitud
			<br />
			terapéutica
		</div>,
		<div className="mb-2 pb-1">Ajuste de dosis</div>,
		<div>
			Tiempo
			<br />
			de tratamiento
		</div>,
	];

	return (
		<div className="h-100">
			<div className="container-fluid bg-turquoise">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					style={{ paddingLeft: "6vh", paddingRight: "6vh" }}
					className="row d-flex justify-content-center align-items-center mt-5"
				>
					<div className="col-7 d-flex flex-column align-items-center text-center">
						<img src={logoSupertest} />
						<div
							className="bg-correct bold d-flex flex-column align-items-center justify-content-center"
							style={{
								borderRadius: "50%",
								width: "56px",
								height: "56px",
								position: "absolute",
								marginTop: "13vh",
								marginLeft: "58vh",
							}}
						>
							<img width={21} src={handGood} />
							<div>+50</div>
						</div>
						<div className="bold txt-yellow-light txt18 mt-2">
							¿Quieres añadir 50 puntos a tu marcador? <br />{" "}
							Responde correctamente a las 3 preguntas del
							supertest.
						</div>
						<div className="txt-white mt-4">
							Solo tienes un intento por pregunta y solo ganarás
							los puntos si respondes correctamente las 3
							preguntas.
						</div>
					</div>
				</div>
				<div
					className="container"
					style={{
						position: "absolute",
						right: "4vh",
						bottom: "4vh",
					}}
				>
					<div className="d-flex justify-content-end">
						<NextButton navigateTo={ROUTES.SUPERTEST} />
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center">
				<div style={{ position: "absolute", bottom: "0" }}>
					<div className="d-flex align-items-end justify-content-center">
						{scenarios?.map((scenario, index) => {
							return (
								<div className="d-flex flex-column align-items-center txt-white me-3">
									<div
										className="txt10"
										style={{ opacity: "0.5" }}
									>
										ESCENARIO {index + 1}
									</div>
									<div className="txt10 mt-1 mb-3 text-center">
										{scenario}
									</div>
									<img src={scenarioPassedBig} />
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(SupertestIntro);
