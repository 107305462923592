import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMedical from "../../assets/logo-medical.png";
import treatment from "../../assets/treatment.png";
import NextButton from "../../components/NextButton";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function Scenario5Part2End({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const scenarioNum = getScenarioByScreen(location.pathname);

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5">
					<div className="col-8">
						<div className="d-flex flex-column align-items-center">
							<div className="txt18 bold txt-white mt-4 text-center">
								Finalmente te decantas por incluir voclosporina
								en el tratamiento de Martina, especialmente
								porque se trata de un fármaco oral que reduce la
								proteinuria de manera más rápida que el
								tratamiento estándar y que no requiere
								monitorización de niveles plasmáticos.
							</div>
							<div className="text-center txt-white my-4">
								Ahora Martina sigue este tratamiento:
							</div>
							<img width={500} src={treatment} />
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton
						navigateTo={ROUTES.TRANSITION_SCENARIOS}
						activeScenario={scenarioNum + 1}
					/>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario5Part2End);
