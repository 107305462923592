import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMedical from "../assets/logo-medical.png";
import scenarioInactive from "../assets/scenario-inactive.png";
import scenarioPassed from "../assets/scenario-passed.png";
import scenario1Active from "../assets/scenario1-active.png";
import scenario2Active from "../assets/scenario2-active.png";
import scenario3Active from "../assets/scenario3-active.png";
import scenario4Active from "../assets/scenario4-active.png";
import scenario5Active from "../assets/scenario5-active.png";
import scenario6Active from "../assets/scenario6-active.png";
import NextButton from "../components/NextButton";
import { ROUTES } from "../utils/constants";

function TransitionScenarios({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const actualScenario = useSelector((state) => state?.group?.actualScenario);
	const activeScenario = prevState?.state?.activeScenario || actualScenario;

	let next =
		activeScenario === 1
			? ROUTES.SCENARIO1_INTRO
			: activeScenario === 2
			? ROUTES.SCENARIO2_INTRO
			: activeScenario === 3
			? ROUTES.SCENARIO3_INTRO
			: activeScenario === 4
			? ROUTES.SCENARIO4_INTRO
			: activeScenario === 5
			? ROUTES.SCENARIO5_INTRO
			: activeScenario === 6
			? ROUTES.SCENARIO6_INTRO
			: activeScenario === 7
			? ROUTES.SCENARIO7_INTRO
			: ROUTES.SUPERTEST_INTRO;

	const navigateTo = prevState?.state?.navigateTo || next;

	const ScenarioItem = ({ title, scenarioNum }) => {
		const active = activeScenario === scenarioNum;
		const passed = activeScenario > scenarioNum;

		function getImageByScenario() {
			if (passed) return scenarioPassed;
			else if (!active) return scenarioInactive;
			else if (active) {
				if (scenarioNum === 1) return scenario1Active;
				else if (scenarioNum === 2) return scenario2Active;
				else if (scenarioNum === 3) return scenario3Active;
				else if (scenarioNum === 4) return scenario4Active;
				else if (scenarioNum === 5) return scenario5Active;
				else if (scenarioNum === 6) return scenario6Active;
				else return scenario1Active;
			}
		}

		return (
			<div
				className={`d-flex flex-column align-items-center text-center ${
					active ? "txt-pink" : "txtWhite mt-5"
				}`}
			>
				<div>ESCENARIO {scenarioNum}</div>
				<div className="bold my-3">{title}</div>
				{title?.length < 12 && !active && <br />}
				<img width={86} src={getImageByScenario()}></img>
			</div>
		);
	};

	return (
		<div className="h-100 bg-turquoise">
			<div className="container-fluid h-100">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					className="row d-flex justify-content-around align-items-start px-5"
					style={{ marginTop: "3vh", lineHeight: "20px" }}
				>
					<div className="col-1 d-flex justify-content-center px-0">
						<ScenarioItem
							title={"Pruebas diagnósticas"}
							scenarioNum={1}
						></ScenarioItem>
					</div>
					<div className="col-1 d-flex justify-content-center px-0">
						<ScenarioItem
							title={"Diagnóstico"}
							scenarioNum={2}
						></ScenarioItem>
					</div>
					<div className="col-1 d-flex justify-content-center px-0">
						<ScenarioItem
							title={"Diagnóstico por imagen"}
							scenarioNum={3}
						></ScenarioItem>
					</div>
					<div className="col-1 d-flex justify-content-center px-0">
						<ScenarioItem
							title={"Tratamiento"}
							scenarioNum={4}
						></ScenarioItem>
					</div>
					<div className="col-1 d-flex justify-content-center px-0">
						<ScenarioItem
							title={"Actitud terapéutica"}
							scenarioNum={5}
						></ScenarioItem>
					</div>
					<div className="col-1 d-flex justify-content-center px-0">
						<ScenarioItem
							title={"Ajuste de dosis"}
							scenarioNum={6}
						></ScenarioItem>
					</div>
					<div className="col-1 d-flex justify-content-center px-0">
						<ScenarioItem
							title={"Tiempo de tratamiento"}
							scenarioNum={7}
						></ScenarioItem>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton navigateTo={navigateTo}></NextButton>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(TransitionScenarios);
