import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import hand from "../../assets/mano.png";
import esquema1 from "../../assets/scenario4-esquema1.png";
import esquema2 from "../../assets/scenario4-esquema2.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import { modalGenericSmall } from "../../utils/logger";

function Scenario4({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const group = useSelector((state) => state?.group);

	const scenarioNum = getScenarioByScreen(location.pathname);

	const [selectedOption, setSelectedOption] = useState(null);

	const [isCompleted, setIsCompleted] = useState(false);

	const [options, setOptions] = useState([
		{
			id: 1,
			text: "Doble terapia",
			correct: false,
			content1: (
				<ui>
					<li className="mt-3">
						Bolo de metilprednisolona 0,25-0,5 g/día durante 3 días
						(y posterior pauta descendente)
					</li>
					<li className="mt-2">Micofenolato de mofetilo 2-3 g/día</li>
					<li className="mt-2">
						Hidroxicloroquina a ≤5 mg/kg/día (hasta un máximo de 400
						mg/d)
					</li>
				</ui>
			),
			content2: (
				<div className="txt-white">
					<div className="bold">
						Regímenes o pautas de corticoides para pacientes con
						nefritis lúpica
					</div>
					<img className="my-2" width="100%" src={esquema1} />
					<div className="txt8" style={{ lineHeight: "10px" }}>
						Rojas-Rivera JE, García-Carro C, Ávila A, et al.
						Documento de consenso del Grupo de Estudio de
						Enfermedades Glomerulares de la Sociedad Española de
						Nefrología (GLOSEN) para el diagnóstico y tratamiento de
						la nefritis lúpica. Nefrología. 2023;43:6-47.
					</div>
				</div>
			),
		},
		{
			id: 2,
			text: "Triple terapia",
			correct: false,
			content1: (
				<ui>
					<li className="mt-3">
						Bolo de metilprednisolona 0,25-0,5 g/día durante 2 días
						(y posterior pauta descendente)
					</li>
					<li className="mt-2">Micofenolato de mofetilo 2 g/día</li>
					<li className="mt-2">
						Hidroxicloroquina a ≤5 mg/kg/día (hasta un máximo de 400
						mg/d)
					</li>
					<li className="mt-2">Voclosporina: 23,7 mg/12 h</li>
				</ui>
			),
			content2: (
				<div className="txt-white">
					<div className="bold">
						Esquema de reducción de glucocorticoides
					</div>
					<img className="my-2" width="100%" src={esquema2} />
					<div className="txt8" style={{ lineHeight: "10px" }}>
						Nota: las dosis de corticoides definidas por protocolo
						dependían del peso de los pacientes (≥45 kg y &lt;
						45kg). Se grafican sólo las dosis para pacientes ≥45 kg.
						En la parte superior de la diapositiva se incluyen todas
						las dosis con independencia del peso
						<br />
						Rovin BH, et al. Efficacy and safety of voclosporin
						versus placebo for lupus nephritis (AURORA 1): a
						double-blind, randomised, multicentre,
						placebo-controlled, phase 3 trial. Lancet. 2021 May
						29;397:2070-2080
					</div>
				</div>
			),
		},
	]);

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		function handleOptionClick() {
			if (isCompleted) return;

			setSelectedOption(id);
		}

		const selected = selectedOption === id;

		function getBackgroundColor() {
			return selected && option?.correct && isCompleted
				? "#D6D431"
				: selected && !option?.correct && isCompleted
				? "#FF5948"
				: selected
				? "#EAF886"
				: "#FFFFFF";
		}

		return (
			<div>
				<div
					className={`d-flex flex-column align-items-center justify-content-center txt-turquoise text-center mt-3 ${
						selected ? "option-selected" : ""
					}`}
					style={{
						position: "relative",
						height: "20vh",
						borderRadius: "10px",
						backgroundColor: getBackgroundColor(),
					}}
					onClick={handleOptionClick}
				>
					<div className="bold">{option?.text}</div>
				</div>
			</div>
		);
	};

	function handleSelect() {
		const option = options?.find((x) => x.id === selectedOption);
		const content = `<div class="text-center">
				<p class="txt-turquoise bold">Has elegido prescribir ${option?.text?.toLowerCase()}.</p>
				<p class="txt-black">Veamos cómo evoluciona Martina.</p>
			</div>`;

		modalGenericSmall({ html: content }).then((res) => {
			if (res.isConfirmed) {
				if (selectedOption === 1)
					handleNavigate(ROUTES.TRANSITION_SCENARIOS);
				else handleNavigate(ROUTES.SCENARIO5_PART2_INTRO);
			}
		});
	}

	function handleNavigate(navigateTo) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));

		updateStats({
			...group,
			time: totalTime,
			actualScenario: scenarioNum + 1,
			lastVisited: navigateTo,
		});
		navigate(navigateTo, { state: { activeScenario: scenarioNum + 1 } });
	}

	const SelectedOptionInfo = ({ id }) => {
		const option = options?.find((x) => x.id === id);
		return (
			<div>
				<div className="row d-flex align-items-start txt-white">
					<div className="col-4">
						<div className="bold txt20">{option?.text}</div>
						<div className="ms-2">{option?.content1}</div>
					</div>
					<div className={`col-8 ${id !== 2 ? "ps-5" : ""}`}>
						{option?.content2}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					{selectedOption ? (
						<div>
							<SelectedOptionInfo id={selectedOption} />
						</div>
					) : (
						<>
							<div className="d-flex justify-content-center align-items-center my-4">
								<div className="col-7 text-center">
									<div className="txtWhite txt20 bold">
										¿Cuál crees que es el mejor tratamiento
										para la paciente en este momento?
									</div>
									<div className="d-flex align-items-center justify-content-center txtWhite mt-4">
										<img className="me-2" src={hand} />
										<div>
											Selecciona el tratamiento más
											adecuado
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex justify-content-center align-items-center">
								<div className="row col-7">
									{options.map((option) => {
										return (
											<div className="col-6">
												<SelectOption id={option.id} />
											</div>
										);
									})}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					right: "0",
					bottom: "13vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					{!isCompleted ? (
						<>
							{selectedOption && (
								<div
									className="btn-main-cancel-white me-3"
									onClick={() => setSelectedOption(null)}
								>
									<div className="text-btn-main">VOLVER</div>
								</div>
							)}
							<div
								className="btn-main"
								onClick={() => handleSelect()}
							>
								<div className="text-btn-main">SELECCIONAR</div>
							</div>
						</>
					) : (
						<NextButton navigateTo={ROUTES.SCENARIO2_RESULT} />
					)}
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario4);
