import Swal from "sweetalert2";
import closeCross from "../assets/close.svg";
import cronometro from "../assets/cronometro.png";
import handGood from "../assets/hand-good.png";
import handWrong from "../assets/hand-wrong.png";
import happyFace from "../assets/happy-face.svg";
import iconPDF from "../assets/icon-pdf.svg";
import logoMedical from "../assets/logo-medical.png";
import mano from "../assets/mano.png";
import neutralFace from "../assets/neutral-face.svg";
import qr from "../assets/qr.png";
import sadFace from "../assets/sad-face.svg";
import videoInstrucciones from "../assets/videos/video-instrucciones.mp4";

export const logError = ({
	error,
	customMessage = "No custom message from backoffice",
}) => {
	error?.response?.status
		? console.log(
				"ERROR\n" +
					`Status: ${error.response.status}.\n` +
					`Status error: ${error.response.statusText}.\n` +
					`API Message: ${error.response.data?.message}\n` +
					`Backoffice Message: ${customMessage}`
		  )
		: console.log(
				"ERROR\n" +
					`Backoffice message: ${customMessage}\n` +
					`${error}`
		  );
};

export const alertError = ({ error, customMessage }) => {
	const message =
		error?.response?.data?.message ||
		error?.response?.statusText ||
		error ||
		customMessage ||
		"Error has no message";

	const params = {
		icon: "error",
		title: customMessage,
		text: message,
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		confirmButtonText: `<div class="text-btn-main">VOLVER</div>`,
		backdrop: "rgba(0,0,0, .7)",
		customClass: {
			title: "txt20",
			confirmButton: "btn-main-cancel bold",
		},
	};

	Swal.fire(params);
};

export const alertResume = ({} = {}) => {
	const params = {
		icon: "question",
		title: "¿Quieres seguir con la partida guardada?",
		confirmButtonText: "Sí, continuar partida",
		cancelButtonText: "No, empezar de nuevo",
		showCancelButton: true,
		reverseButtons: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		customClass: {
			popup: "modal-resolution-container",
			confirmButton: "btn-main w-fit-content bold",
			cancelButton: "btn-main-cancel w-fit-content bold",
		},
	};
	return Swal.fire(params);
};

export const alertGroup = ({ data } = {}) => {
	const html = `
	<div class='text-start'>
		<div class='bold'>Nombre: <span class='regular'>${
			data.group.fullName
		}</span></div>
		<div class='bold'>Mesa: <span class='regular'>${
			data.group.tableNum
		}</span></div>
		<div class='bold'>Participantes:<br/> ${data.members
			.map((member) => {
				return `<span class='ps-3 my-1 regular'>${member.fullName}<br/></span>
		`;
			})
			.join("")}</div>
	</div>`;
	const params = {
		icon: "question",
		html: html,
		title: "¿Es este tu grupo?",
		confirmButtonText: "Sí, continuar partida",
		cancelButtonText: "No, escoger otro",
		showCancelButton: true,
		reverseButtons: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		customClass: {
			confirmButton: "btn-main w-fit-content",
			cancelButton: "btn-main-cancel w-fit-content",
		},
	};
	return Swal.fire(params);
};

export const modalTableNum = ({ tableNum } = {}) => {
	let html = `<div class="p-4 d-flex flex-column align-items-center text-center txt22 txt-blue">
			<div>
				Se te ha asignado la mesa <span class="bold">${tableNum}.</span>
			</div>
			<div class="mt-3">¡Acuérdate del número por si hay cualquier problema!</div>
		</div>`;
	const params = {
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: true,
		showCloseButton: true,
		showConfirmButton: true,
		showCancelButton: false,
		confirmButtonText: `<div class="d-flex align-items-center justify-content-center">DE ACUERDO</div>`,
		backdrop: "rgba(0,0,0, 0.7)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-question-explanation",
			confirmButton: "btn-main",
			closeButton: "modal-btn-close",
		},
	};
	Swal.fire(params);
};

export const modalText = ({ title, html, showCancelButton } = {}) => {
	const params = {
		title: title || "",
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		showCancelButton: false,
		backdrop: "rgba(0,0,0, 0.7)",
		closeButtonHtml: `<div class="modal-btn-close white"></div>`,
		customClass: {
			popup: "modal-text-container",
			htmlContainer: "modal-text-html",
			title: "modal-text-title",
			closeButton: "modal-btn-close white",
			cancelButton: "btn-main-cancel w-fit-content",
		},
	};
	Swal.fire(params);
};

export const modalFichaTecnica = ({} = {}) => {
	const html = `<div class="txtWhite">
      <div class="d-flex align-items-end">
        <img src=${qr} />
        <div class="ms-3">Accede a la <a class="txtWhite" target="_blank" href="https://cima.aemps.es/cima/publico/detalle.html?nregistro=1221678001">ficha técnica</a> de Lupkynis ®</div>
      </div>
      <div class="mt-5">
        <span class="bold">
          CONDICIONES DE PRESCRIPCIÓN Y DISPENSACIÓN
        </span>
        <div class="mt-2">
          Medicamento sujeto a prescripción médica. Dispensación hospitalaria
          sin cupón precinto.
        </div>
      </div>
      <div class="mt-3">
        <span class="bold">
          CONDICIONES DE PRESTACIÓN DEL SISTEMA NACIONAL DE SALUD
        </span>
        <div class="mt-2">Reembolsable por el Sistema Nacional de Salud.</div>
      </div>
      <div class="mt-3">
        <span class="bold">PRESENTACIONES Y PRECIO</span>
        <div class="mt-2">
          Lupkynis 7,9 mg cápsulas blandas, 180 cápsulas. Precio Notificado
          autorizado: 780€.
        </div>
      </div>
      <div class="mt-5 txt14 d-flex justify-content-between align-items-center w-100">
        <div>Fecha elaboración: Abril 2024</div>
        <div>ES-LUP-2400071 v1</div>
      </div>
    </div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		showCancelButton: false,
		backdrop: "rgba(0,0,0, 0.7)",
		closeButtonHtml: `<div class="modal-btn-close white"></div>`,
		customClass: {
			popup: "modal-ficha-tecnica-container",
			htmlContainer: "modal-ficha-tecnica-html",
			closeButton: "modal-btn-close white",
		},
	};
	Swal.fire(params);
};

export const modalPapel = ({ image } = {}) => {
	let html = `<div class="d-flex justify-content-center align-items-center text-center"><img src=${image}></img></div>`;
	const params = {
		html: html || "",
		allowOutsideClick: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		showConfirmButton: false,
		showCancelButton: true,
		cancelButtonText: `<div class="d-flex align-items-center justify-content-center">
			<img class="me-2" src=${closeCross} alt="close"></img>CERRAR</div>`,
		backdrop: "rgba(0,0,0, 0.7)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-papel-container",
			closeButton: "modal-btn-close",
			cancelButton: "btn-main-cancel w-fit-content px-4",
		},
	};
	Swal.fire(params);
};

export const modalConfirm = ({ text, titleClass, htmlText } = {}) => {
	let html = htmlText;
	const params = {
		title: text,
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		allowOutsideClick: false,
		confirmButtonText: `<div class="text-btn-main">ACEPTAR</div>`,
		cancelButtonText: `<div class="text-btn-main">CANCELAR</div>`,
		showCancelButton: true,
		backdrop: "rgba(0,0,0, .7)",
		customClass: {
			popup: "modal-confirm-container",
			title: titleClass || "modal-confirm-title bold",
			confirmButton: "btn-main bold",
			cancelButton: "btn-main-cancel bold",
		},
	};
	return Swal.fire(params);
};

export const modalQuestionResolution = ({
	correct,
	textHtml,
	fontCourier,
	allowOutsideClick,
	showConfirmButton,
	showCancelButton,
	customConfirmButtonHtml,
	customCancelButtonHtml,
	cancelFitContent,
	popupClass,
} = {}) => {
	let html = `<div class="text-center">
    <div>
      <img src=${
			correct === null ? neutralFace : correct ? happyFace : sadFace
		} alt='cara' height="50"/>
    </div>
    <br />
    <div>${textHtml}</div>
  </div>`;
	let fontClass = fontCourier ? "txt-courier" : "";

	const params = {
		html: html,
		allowOutsideClick:
			allowOutsideClick !== null ? allowOutsideClick : false,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		showCancelButton:
			showCancelButton === true || showCancelButton === false
				? showCancelButton
				: true,
		showConfirmButton:
			showConfirmButton === true || showConfirmButton === false
				? showConfirmButton
				: false,
		confirmButtonText: `<div class="d-flex align-items-center justify-content-center">${
			customConfirmButtonHtml
				? customConfirmButtonHtml
				: `<img class="me-2" src=${iconPDF}></img><div class="text-btn-main">VER CRITERIOS GLIM</div></div>`
		}</div>`,
		cancelButtonText: `<div class="d-flex align-items-center justify-content-center ${fontClass}">${
			customCancelButtonHtml
				? customCancelButtonHtml
				: `<img class="me-2" src=${closeCross} alt="close"></img>CERRAR</div>`
		}`,
		backdrop: "rgba(0,0,0, .7)",
		customClass: {
			popup: popupClass ? popupClass : "modal-question-explanation",
			confirmButton: "btn-main w-fit-content",
			cancelButton: `btn-main-cancel ${
				cancelFitContent ? "w-fit-content" : ""
			}`,
		},
	};
	return Swal.fire(params);
};

export const modalQuestion1Feedback = ({
	question,
	answerHtml,
	correct,
	points,
} = {}) => {
	let html = `<div class="text-center">
			<div class="txt-turquoise bold">${question}</div>
			<div class="d-flex justify-content-center my-3">
			<div class="${
				correct ? "bg-correct" : "bg-incorrect"
			} txt-black w-fit-content p-2 bold">
				<img
					width="25"
					src=${correct ? handGood : handWrong}
					class="me-1"
				/>
				${correct ? "+" : ""}
				${points} puntos
			</div>
			</div>
			<div class="txt18 mt-2">${answerHtml}</div>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		showConfirmButton: true,
		allowOutsideClick: false,
		backdrop: "rgba(0,0,0, .7)",
		confirmButtonText: `<div class="text-btn-main">AVANZAR</div>`,
		customClass: {
			popup: "modal-question-explanation",
			confirmButton: "btn-main",
		},
	};
	return Swal.fire(params);
};

export const modalQuestion2Feedback = ({
	question,
	answerHtml,
	correct,
	points,
} = {}) => {
	let html = `<div class="text-center">
			<div class="txt-turquoise bold">${question}</div>
			<div class="d-flex justify-content-center my-3">
			<div class="${
				correct ? "bg-correct" : "bg-incorrect"
			} txt-black w-fit-content p-2 bold">
				<img
					width="25"
					src=${correct ? handGood : handWrong}
					class="me-1"
				/>
				${correct ? "+" : ""}
				${points} puntos
			</div>
			</div>
			<div class="txt18 mt-2">${answerHtml}</div>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		showConfirmButton: true,
		allowOutsideClick: false,
		backdrop: "rgba(0,0,0, .7)",
		confirmButtonText: `<div class="text-btn-main">AVANZAR</div>`,
		customClass: {
			popup: "modal-question-explanation w-fit-content",
			confirmButton: "btn-main",
		},
	};
	return Swal.fire(params);
};

export const modalQuestion3Feedback = ({ text, correct, points } = {}) => {
	let html = `<div class="text-center">
	${text}
			<div class="d-flex justify-content-center my-3">
			<div class="${
				correct ? "bg-correct" : "bg-incorrect"
			} txt-black w-fit-content p-2 bold">
				<img
					width="25"
					src=${correct ? handGood : handWrong}
					class="me-1"
				/>
				${correct ? "+" : ""}
				${points} puntos
			</div>
			</div>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		showConfirmButton: true,
		allowOutsideClick: false,
		backdrop: "rgba(0,0,0, .7)",
		confirmButtonText: `<div class="text-btn-main">IR A LAS IMÁGENES</div>`,
		customClass: {
			popup: "modal-question-explanation w-fit-content",
			confirmButton: "btn-main w-fit-content",
		},
	};
	return Swal.fire(params);
};

export const modalQuestion5Feedback = ({ text, correct, points } = {}) => {
	let html = `<div class="text-center">
	${text}
			<div class="d-flex justify-content-center my-3">
			<div class="${
				correct ? "bg-correct" : "bg-incorrect"
			} txt-black w-fit-content p-2 bold">
				<img
					width="25"
					src=${correct ? handGood : handWrong}
					class="me-1"
				/>
				${correct ? "+" : ""}
				${points} puntos
			</div>
			</div>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		showConfirmButton: true,
		allowOutsideClick: !correct,
		backdrop: "rgba(0,0,0, .7)",
		confirmButtonText: `<div class="text-btn-main">${
			correct ? "IR AL GRÁFICO" : "VOLVER"
		}</div>`,
		customClass: {
			popup: "modal-question-explanation",
			confirmButton: "btn-main w-fit-content",
		},
	};
	return Swal.fire(params);
};

export const modalCasoClinico = ({ correct, textHtml } = {}) => {
	let html = `<div class="text-center">
    <div>
      <img src=${
			correct === null ? neutralFace : correct ? happyFace : sadFace
		} alt='cara' height="50"/>
    </div>
    <br />
    <div>${textHtml}</div>
  </div>`;

	const params = {
		html: html,
		allowOutsideClick: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showCancelButton: false,
		showConfirmButton: false,
		backdrop: "rgba(0,0,0, .7)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-caso-clinico-resolution",
			htmlContainer: "modal-caso-clinico-html",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const modalWait = ({ html }) => {
	let result = `<div class="d-flex flex-column align-items-center txt22 txt-blue">${html}</div>`;
	const params = {
		html: result,
		allowEnterKey: false,
		allowEscapeKey: false,
		confirmButtonText: `<div class="text-btn-main">ACEPTAR</div>`,
		showCancelButton: false,
		customClass: {
			popup: "modal-wait-container",
			confirmButton: "btn-main",
		},
	};
	return Swal.fire(params);
};

export const modalPDF = ({
	pdf,
	title,
	showCloseButton,
	allowOutsideClick,
	customCancelButtonHtml,
} = {}) => {
	let html = `<iframe
			id="pdfviewer"
			src=${pdf}
			frameborder="0"
			width="90%"
			height="550px"
			type="application/pdf"
		></iframe>`;
	const params = {
		title: title || "CRITERIOS GLIM",
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton:
			showCloseButton === true || showCloseButton === false
				? showCloseButton
				: true,
		allowOutsideClick:
			allowOutsideClick === true || allowOutsideClick === false
				? allowOutsideClick
				: true,
		showCancelButton: true,
		showConfirmButton: false,
		cancelButtonText: customCancelButtonHtml
			? customCancelButtonHtml
			: `<div class="d-flex align-items-center justify-content-center">AVANZAR</div>`,
		backdrop: "rgba(0,0,0, .7)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-pdf-container",
			htmlContainer: "modal-pdf-html",
			title: "modal-text-title",
			cancelButton: "btn-main-cancel",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const modalIntro = () => {
	let html = `
		<video class='video-playerB' autoplay controls='true' id='video' loop controlsList="nofullscreen" src=${videoInstrucciones}> 
			<source src=${videoInstrucciones} type='video/mp4'>
		</video>`;
	const params = {
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		backdrop: "rgba(0,0,0, .7)",
		closeButtonHtml: `<div class="modal-btn-close-video"></div>`,
		customClass: {
			popup: "modal-video-container",
			htmlContainer: "modal-video-html",
			closeButton: "modal-btn-close-video",
		},
	};
	Swal.fire(params);
};

export const modalStartGame = () => {
	let html = `<div class="d-flex flex-column align-items-center justify-content-center">
			<div class="txt-turquoise bold txt20">
				Ayuda a Martina resolviendo el caso.
			</div>
			<img class="mt-4" width="15%" src=${cronometro} />
			<div class="d-flex align-items-start mt-4">
				<img width="10%" src=${mano} />
				<ul>
					<li>
						Es fundamental que
						<span class="bold">trabajéis en equipo</span>: recopilad
						todos los datos y responded correctamente a las
						preguntas.
					</li>
					<li>
						Las imágenes con la descripción correcta se quedarán
						fijas.
					</li>
					<li>
						Ganará el equipo que consiga una puntuación más alta.
					</li>
				</ul>
			</div>
		</div>`;
	const params = {
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		showCloseButton: false,
		showConfirmButton: true,
		backdrop: "rgba(0,0,0, 0.7)",
		confirmButtonText: "AVANZAR",
		customClass: {
			popup: "modal-start-game",
			htmlContainer: "modal-start-game-html",
			confirmButton: "btn-main",
		},
	};
	return Swal.fire(params);
};

export const modalAnaliticaDerivacion = () => {
	let html = `<div>
			<div class="d-flex align-items-center justify-content-between">
				<img src=${logoMedical} />
				<div class="txt-dark-green text-left">
					<div class="bold">Analítica de derivación</div>
					<div>Paciente: Martina Ferrater</div>
					<div>Edad: 37 años</div>
				</div>
			</div>
			<div class="text-left mt-4">
				<div class="txt-black">
					<span class="bold">Hb:</span> 10,9 g/dl
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Leucocitos:</span> 2070/µl
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Plaquetas:</span> 178 000 2070/µl
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Glucosa:</span> 97 mg/dl
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Creatinina:</span> 0,71 mg/dl
				</div>
				<div class="txt-black mt-2">
					<span class="bold">FG</span> > 90 ml/min/1,73 m<sup>2</sup>
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Urea:</span> 22 mg/dl
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Ionograma</span> normal
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Proteína C reactiva:</span> 7,2 mg/l
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Bioquímica hepática</span> normal
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Ferritina:</span> 113 ng/ml
				</div>
				<div class="txt-black mt-2">
					<span class="bold">
						Índice de saturación de transferrina:
					</span>
					27 %
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Sedimento de orina:</span> leucocitos
					10-15/campo, hematíes 15-20/campo, no
					bacterias/cilindros/cristales
				</div>
				<div class="txt-black mt-2">
					<span class="bold">Tira reactiva (x 2):</span> proteinuria
					150-200 mg/dl
				</div>
			</div>
		</div>`;
	const params = {
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: true,
		showConfirmButton: false,
		backdrop: "rgba(0,0,0, 0.7)",
		customClass: {
			popup: "modal-historial",
		},
	};
	Swal.fire(params);
};

export const modalHistoriaClinica = () => {
	let html = `<div>
			<div class="d-flex align-items-center justify-content-between">
				<img src=${logoMedical} />
				<div class="txt-dark-green text-left">
					<div class="bold">Historia Clínica</div>
					<div>Paciente: Martina Ferrater</div>
					<div>Edad: 37 años</div>
				</div>
			</div>
			<div class="text-left mt-4 txt-black bullet-grey w-75">
				<ul>
					<li>No fumadora.</li>
					<li class="mt-2">Bebedora de alcohol ocasional.</li>
					<li class="mt-2">Anemia ferropénica, ferroterapia oral irregular.</li>
					<li class="mt-2">
						Artritis en 1.ª y 2.ª articulaciones metacarpofalángicas
						derechas a los 31 años. Se realizaron pruebas y se trató
						con corticoides (no datos en historia clínica).
					</li>
					<li class="mt-2">No problemas intestinales o gastrointestinales.</li>
					<li class="mt-2">
						No cólicos reno ureterales ni infecciones del tracto
						urinario.
					</li>
					<li class="mt-2">No embarazos ni abortos.</li>
					<li class="mt-2">No bajo peso al nacer.</li>
				</ul>
				<div class="bold mt-4">Antecedentes familiares</div>
				<ul>
					<li class="mt-2">Madre con artritis reumatoide.</li>
					<li class="mt-2">Hermana mayor con lupus eritematoso sistémico.</li>
				</ul>
				<div class="bold mt-4">Tratamiento actual</div>
				<ul>
					<li class="mt-2">Sulfato ferroso 105 mg 1 comprimido/24 horas.</li>
				</ul>
			</div>
		</div>`;
	const params = {
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: true,
		showConfirmButton: false,
		backdrop: "rgba(0,0,0, 0.7)",
		customClass: {
			popup: "modal-historial",
		},
	};
	Swal.fire(params);
};

export const modalInformeMedico = () => {
	let html = `<div>
			<div class="d-flex align-items-center justify-content-between">
				<img src=${logoMedical} />
				<div class="txt-dark-green text-left">
					<div class="bold">Informe</div>
					<div>Paciente: Martina Ferrater</div>
					<div>Edad: 37 años</div>
				</div>
			</div>
			<div class="text-left mt-4 txt-black bullet-black w-75">
				<ul>
					<li class="bold">Episodio de gastroenteritis aguda a los 4 meses de haber iniciado el tratamiento para la nefritis lúpica. </li>
					<li class="mt-2"><span class="bold">PA:</span> 102/61 mmHg.</li>
					<li class="mt-2">
						<span class="bold">Temperatura corporal:</span> 36,3 ºC. 
					</li>
					<li class="mt-2 bold">Exploración normal. </li>
					<li class="mt-2">
						<span class="bold">Cr:</span> 1,1 mg/dl. 
					</li>
					<li class="mt-2 bold">Proteinuria y hematuria normales. </li>
					<li class="mt-2 bold">C3, C4 normales. </li>
					<li class="mt-2"><span class="bold">TFGe:</span> 62 ml/min/1,73 m2. </li>
				</ul>
			</div>
      <div><strong>Cr:</strong> creatina; <strong>PA:</strong> presión arterial; <strong>TFGe:</strong> tasa de filtración glomerular estimada</div>
		</div>`;
	const params = {
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: true,
		showConfirmButton: false,
		backdrop: "rgba(0,0,0, 0.7)",
		customClass: {
			popup: "modal-historial",
		},
	};
	Swal.fire(params);
};

export const modalGeneric = ({ html }) => {
	let data = html;

	const params = {
		html: data || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		showConfirmButton: true,
		confirmButtonText: "AVANZAR",
		backdrop: "rgba(0,0,0, 0.7)",
		customClass: {
			popup: "modal-start-game",
			htmlContainer: "modal-start-game-html",
			confirmButton: "btn-main",
		},
	};
	return Swal.fire(params);
};

export const modalGenericSmall = ({
	html,
	confirmButtonText,
	allowOutsideClick,
}) => {
	let data = html;

	const params = {
		html: data || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: allowOutsideClick,
		showConfirmButton: true,
		confirmButtonText: confirmButtonText || "AVANZAR",
		backdrop: "rgba(0,0,0, 0.7)",
		customClass: {
			popup: "modal-start-game-small",
			htmlContainer: "modal-start-game-html",
			confirmButton: "btn-main",
		},
	};
	return Swal.fire(params);
};

export const modalQuestionInfo = ({ html }) => {
	let data = html;

	const params = {
		html: data || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		showConfirmButton: true,
		confirmButtonText: "AVANZAR",
		backdrop: "rgba(0,0,0, 0.7)",
		customClass: {
			popup: "modal-question-info",
			htmlContainer: "modal-question-info-html",
			confirmButton: "btn-main",
		},
	};
	return Swal.fire(params);
};

export const modalImage = ({ image }) => {
	let html = `<div>
		<img style="height: 100vh;" src=${image} />
	</div>`;

	const params = {
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: true,
		showCloseButton: true,
		showConfirmButton: false,
		backdrop: "rgba(0,0,0, 1)",
		closeButtonHtml: `<div class="modal-btn-close-button"></div>`,
		customClass: {
			popup: "modal-image",
			htmlContainer: "modal-image-html",
			closeButton: "modal-btn-close-button",
		},
	};
	return Swal.fire(params);
};
