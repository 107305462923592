import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getGameById } from "../api/game";
import logoMedical from "../assets/logo-medical.png";
import martina from "../assets/martina.png";
import { ROUTES } from "../utils/constants";
import { updateTimer } from "../utils/helpers";
import { modalStartGame, modalWait } from "../utils/logger";

function Context({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	const group = useSelector((state) => state?.group);

	const handleStartGame = () => {
		let time = group?.time ? parseInt(group?.time) : 0;
		updateTimer(time);
	};

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5 pt-5">
					<div className="col-9">
						<div className="ms-5 ps-4 d-flex align-items-center">
							<img width={350} src={martina} />
							<div
								className="ms-4 txtWhite txt20 text-center"
								style={{ lineHeight: "30px" }}
							>
								Esta es Martina, 37 años. <br />
								Le han diagnosticado{" "}
								<span className="bold">
									lupus eritematoso sistémico
								</span>{" "}
								(LES){" "}
								<span className="bold">
									y una proteinuria elevada
								</span>
								.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<div
						className="btn-main"
						onClick={(e) => {
							modalStartGame().then((res) => {
								if (res.isConfirmed) {
									getGameById(group?.game).then((res) => {
										if (
											res.status === 200 &&
											res.data?.active
										) {
											handleStartGame();
											navigate(
												ROUTES.TRANSITION_SCENARIOS,
												{
													state: {
														activeScenario: 1,
													},
												}
											);
										} else {
											modalWait({
												html: `<div class="d-flex flex-column">
														<div class="txt-turquoise txt18 bold">El juego aún no ha empezado.</div>
														<div class="mt-2 txt-turquoise txt18">
															Espera a que el moderador dé la orden.
														</div>
													</div>`,
											});
										}
									});
								}
							});
						}}
					>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Context);
