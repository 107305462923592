import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import analiticaDerivacion from "../../assets/analitica-derivacion.png";
import historiaClinica from "../../assets/historia-clinica.png";
import logoMedical from "../../assets/logo-medical.png";
import martinaLupa from "../../assets/martina-lupa.png";

import NextButton from "../../components/NextButton";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";
import {
	modalAnaliticaDerivacion,
	modalHistoriaClinica,
} from "../../utils/logger";

function Scenario1Intro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas2">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					style={{ paddingLeft: "6vh" }}
					className="row d-flex justify-content-start align-items-start mt-5 pt-5"
				>
					<div
						className="col-3 line-height-40"
						style={{ letterSpacing: 3 }}
					>
						<div className="txtWhite txt20">ESCENARIO 1</div>
						<div className="txtWhite txt30 bold">
							Pruebas <br />
							diagnósticas
						</div>
					</div>
					<div className="col-3 d-flex justify-content-center mt-5">
						<img width={300} src={martinaLupa} />
					</div>
					<div className="col-6">
						<div className="d-flex flex-column align-items-center txtWhite text-center mt-5">
							<div className="txt20 bold">
								¿Qué sabemos sobre la paciente?
							</div>
							<div className="txt18 mt-3 w-75">
								Antes de que Martina entre en tu consulta,
								revisas la información que tienes sobre ella.
							</div>
							<img
								className="mt-4 mb-3"
								width={194}
								src={historiaClinica}
								onClick={() => modalHistoriaClinica()}
							/>
							<img
								width={194}
								src={analiticaDerivacion}
								onClick={() => modalAnaliticaDerivacion()}
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton
						navigateTo={ROUTES.SCENARIO1_CONVERSATION_INTRO}
					/>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario1Intro);
