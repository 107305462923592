import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import handGood from "../../assets/hand-good.png";
import iconFalseSelected from "../../assets/icon-false-selected.png";
import iconFalse from "../../assets/icon-false.png";
import iconTrueSelected from "../../assets/icon-true-selected.png";
import iconTrue from "../../assets/icon-true.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateScore, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import { alertError, modalGeneric } from "../../utils/logger";

const QUIZ_ID = QUIZZES.SCENARIO7_2;
const SCENARIO_ID = SCENARIOS.SCENARIO_7;
const NAVIGATE_TO = ROUTES.SCENARIO7_QUESTION3;

const CORRECT_ANSWER_POINTS = 10;

function Scenario7Question2({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const participant = useSelector((state) => state.group);

	const scenarioNum = getScenarioByScreen(location.pathname);

	const [selected, setSelected] = useState(null);

	const [time, setTime] = useState(0);
	const [isCompleted, setIsCompleted] = useState(false);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	function checkAnswers() {
		if (selected === null) {
			alertError({
				error: " ",
				customMessage: "Para avanzar, debes seleccionar una respuesta.",
			});
			return;
		}

		const correct = selected;

		const text = `<div class="txt14 txt-black">
					<div class="text-center bold txt-turquoise txt18">
						${correct ? "¡Correcto!" : "¡Vaya! Esta afirmación es verdadera"}
					</div>
					${
						correct
							? `<div class="d-flex justify-content-center mt-3">
				<div class="bg-correct p-2 w-fit-content">
					<img width="25" src=${handGood} class="me-1" />
					<span class="bold">+${CORRECT_ANSWER_POINTS}</span> puntos
				</div>
			</div>`
							: ""
					}
					<div class="mt-4 text-left">
						9.
						<span class="bold">
							Después de la respuesta renal, el tratamiento de la NL debe continuar durante al menos 3 años;
						</span>
						Los pacientes tratados inicialmente con micofenolato solo o en combinación con belimumab o un inhibidor de la calcineurina deben seguir tomando estos fármacos (1a/A), mientras que el micofenolato o la azatioprina deben sustituir a la ciclofosfamida para aquellos pacientes tratados inicialmente solo con ciclofosfamida (1a/A) o en combinación con belimumab (1a/A).
					</div>
					<div class="mt-4 txt8">
						Fanouriakis A, Kostopoulou M, Andersen J, et al. EULAR recommendations for management of systemic lupus erythematosus: 2023 update. Ann Rheum Dis. 2024;83:15-29. Disponible en: https://ard.bmj.com/content/83/1/15 [última consulta: 18 mar 2024].
					</div>
				</div>`;

		modalGeneric({ html: text }).then((res) => {
			if (res.isConfirmed) submitAnswer({ correct: correct });
		});
	}

	async function submitAnswer({ correct }) {
		setIsCompleted(true);

		const score = correct ? CORRECT_ANSWER_POINTS : 0;

		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateScore(score, SCORE_TYPES.SCORE));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						lastVisited: NAVIGATE_TO,
					});
					navigate(NAVIGATE_TO, {
						state: { activeScenario: scenarioNum + 1 },
					});
				}
			})
			.catch((error) => {});
	}

	function handleSelect(value) {
		if (isCompleted) return;
		setSelected(value);
	}

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					<div className="row d-flex justify-content-center txtWhite txt20 bold mt-5 pt-5">
						<div className="col-7 text-center">
							¿Qué recomiendan otras sociedades científicas acerca
							de la duración del tratamiento?
						</div>
					</div>
					<div className="row d-flex justify-content-center align-items-start mt-4 pt-2">
						<div className="col-8">
							<div
								className="text-center txt-dark-green bg-white option-selected p-3"
								style={{ borderRadius: "10px" }}
							>
								Según las últimas recomendaciones de la EULAR (2024)<sup>1</sup>,
								tras la respuesta renal, el tratamiento de la
								nefritis lúpica debe continuar al menos durante
								3 años.
							</div>
							<div className="d-flex align-items-center justify-content-center mt-4 pt-3">
								<img
									width={50}
									onClick={() => handleSelect(true)}
									src={selected ? iconTrueSelected : iconTrue}
								/>
								<img
									width={50}
									className="ms-3"
									onClick={() => handleSelect(false)}
									src={
										selected === false
											? iconFalseSelected
											: iconFalse
									}
								/>
							</div>
						</div>
					</div>
					<div className="row d-flex justify-content-center align-items-start mt-4 pt-2">
						<div className="col-4 txt10">
							<sup>1</sup>Fanouriakis A, Kostopoulou M, Andersen J, et al. EULAR recommendations for management of systemic lupus erythematosus: 2023 update. Ann Rheum Dis. 2024;83:15-29. Disponible en: https://ard.bmj.com/content/83/1/15 [última consulta: 18 mar 2024].<br/>
							<strong>EULAR:</strong> European Alliance of Associations for Rheumatology
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					right: "4vh",
					bottom: "17vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					{!isCompleted ? (
						<div
							className="btn-main"
							onClick={() => checkAnswers()}
						>
							<div className="text-btn-main">AVANZAR</div>
						</div>
					) : (
						<NextButton navigateTo={ROUTES.SCENARIO7_QUESTION3} />
					)}
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario7Question2);
