import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import handGood from "../../assets/hand-good.png";
import logoSupertest from "../../assets/logo-supertest-blue.png";
import { useTimer } from "../../hooks/useTimer";
import { updateScore, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import { getTimer, updateStats } from "../../utils/helpers";
import { alertError, modalGeneric } from "../../utils/logger";

const QUESTION_EXPLANATION = `<div class="text-center txt-black">
		<div class="txt-turquoise bold">
			¿Qué recomiendan otras sociedades científicas acerca de la duración
			del tratamiento?
		</div>
		<div class="d-flex justify-content-center mt-4">
			<div class="bg-correct p-2 w-fit-content">
				<img width="25" src=${handGood} class="me-1" />
				<span class="bold">+10</span> puntos
			</div>
		</div>
		<div class="mt-4">
			Vamos a plantearte 2 enunciados que pueden ser Verdaderos o Falsos.
		</div>
		<div class="txt-turquoise bold mt-4">¿Preparado?</div>
	</div>`;

const SCENARIO_ID = SCENARIOS.SUPERTEST;
const NAVIGATE_TO = ROUTES.SUPERTEST_END;

const CORRECT_ANSWER_POINTS = 50;

function Supertest({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const participant = useSelector((state) => state.group);

	const [time, setTime] = useState(0);
	const [isCompleted, setIsCompleted] = useState(false);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const [questionOptions, setQuestionOptions] = useState({
		1: {
			quizId: QUIZZES.SUPERTEST_1,
			title: "¿Cuál de las siguientes afirmaciones NO es correcta?",
			options: [
				{
					id: "a)",
					text: "La NL constituye la afectación orgánica grave más frecuente del LES, dado que hasta el 60 % de los pacientes con LES pueden llegar a tener NL durante el curso de su vida.",
					selected: false,
					correct: false,
				},
				{
					id: "b)",
					text: "La NL se caracteriza por la aparición de proteinuria en grado variable, alteraciones en el sedimento urinario, deterioro de la función renal y en algunos casos, desarrollo de hipertensión arterial.",
					selected: false,
					correct: false,
				},
				{
					id: "c)",
					text: "La presencia de NL se asocia con un mayor riesgo de complicaciones cardiovasculares, daño orgánico irreversible y mortalidad prematura.",
					selected: false,
					correct: false,
				},
				{
					id: "d)",
					text: "Son incorrectas las afirmaciones B y C.",
					selected: false,
					correct: false,
				},
				{
					id: "e)",
					text: "No hay afirmaciones incorrectas.",
					selected: false,
					correct: true,
				},
			],
		},
		2: {
			quizId: QUIZZES.SUPERTEST_2,
			title: "¿Cuál de las siguientes afirmaciones acerca de voclosporina es correcta?",
			options: [
				{
					id: "a)",
					text: "Ha demostrado ser eficaz en nefritis lúpica de nuevo diagnóstico.",
					selected: false,
					correct: false,
				},
				{
					id: "b)",
					text: "Ha demostrado ser eficaz en recaídas.",
					selected: false,
					correct: false,
				},
				{
					id: "c)",
					text: "Se puede utilizar en pacientes con nefritis lúpica de clase IV y V, pero no III.",
					selected: false,
					correct: false,
				},
				{
					id: "d)",
					text: "A y B son correctas.",
					selected: false,
					correct: true,
				},
				{
					id: "e)",
					text: " A, B y C son correctas.",
					selected: false,
					correct: false,
				},
			],
		},
		3: {
			quizId: QUIZZES.SUPERTEST_3,
			title: "Voclosporina, como parte del tratamiento estándar de NL durante 3 años, tiene un perfil riesgo-beneficio positivo. ¿Cuál de las siguientes afirmaciones es correcta en relación con la afirmación anterior?",
			options: [
				{
					id: "a)",
					text: "La preservación de la función renal a largo plazo, junto con los resultados de seguridad favorables del estudio Aurora 2, dan a entender que esta afirmación es verdadera.",
					selected: false,
					correct: true,
				},
				{
					id: "b)",
					text: "Aunque la voclosporina puede ofrecer beneficios terapéuticos en el tratamiento de la NL, su uso como parte del tratamiento estándar durante 3 años aún plantea interrogantes sobre su tolerabilidad, por lo que esta afirmación, tal y como está formulada, es falsa.",
					selected: false,
					correct: false,
				},
				{
					id: "c)",
					text: "Si bien los resultados de seguridad en el estudio Aurora 1 fueron positivos, los del estudio Aurora 2 desaconsejan el uso de voclosporina durante un período mayor de un año, por lo que la afirmación es falsa.",
					selected: false,
					correct: false,
				},
			],
		},
	});

	const [currentQuestionNum, setCurrentQuestionNum] = useState(1);

	const SelectOption = ({ id }) => {
		let option = questionOptions[currentQuestionNum]?.options?.find(
			(x) => x.id === id
		);

		function getBackgroundColor() {
			return option?.selected && option?.correct && isCompleted
				? "#D6D431"
				: option?.selected && !option?.correct && isCompleted
				? "#FF5948"
				: option?.selected
				? "#EAF886"
				: "#FFFFFF";
		}

		function handleOptionClick() {
			if (isCompleted) return;

			let updatedOptions = questionOptions[
				currentQuestionNum
			]?.options?.map((x) =>
				x.id === id
					? {
							...x,
							selected: !x.selected,
					  }
					: { ...x, selected: false }
			);

			setQuestionOptions((prevOptions) => ({
				...prevOptions,
				[currentQuestionNum]: {
					...prevOptions[currentQuestionNum],
					options: updatedOptions,
				},
			}));
		}

		return (
			<div>
				<div
					className={`d-flex align-items-center justify-content-start txt-dark-green mt-3 p-3 ${
						option?.selected ? "option-selected bold" : ""
					}`}
					style={{
						height: "fit-content",
						lineHeight: "20px",
						borderRadius: "10px",
						backgroundColor: getBackgroundColor(),
					}}
					onClick={handleOptionClick}
				>
					<div className="d-flex align-items-start justify-content-start">
						<div className="bold">{option?.id}</div>
						<div className="ms-1">{option?.text}</div>
					</div>
				</div>
			</div>
		);
	};

	function checkAnswers() {
		if (
			!questionOptions[currentQuestionNum]?.options?.some(
				(x) => x?.selected
			)
		) {
			alertError({
				error: " ",
				customMessage: "Para avanzar, debes seleccionar una opción.",
			});
			return;
		}

		if (currentQuestionNum === Object.keys(questionOptions)?.length) {
			const correct = Object.keys(questionOptions).every((key) =>
				questionOptions[key]?.options?.some(
					(x) => x?.selected && x?.correct
				)
			);

			const text = `<div class="txt14 txt-black">
					<div class="text-center bold txt-turquoise txt18">
				${
					correct
						? "¡Enhorabuena! ¡Has acertado todas las respuestas!"
						: "¡Vaya! Has fallado al menos una de las respuestas…"
				}
			</div>
			<div class="d-flex justify-content-center mt-3">
				${
					correct
						? `<div class="bg-correct p-2 w-fit-content">
						<img width="25" src=${handGood} class="me-1" />
						<span class="bold">+${CORRECT_ANSWER_POINTS}</span> puntos
					   </div>`
						: "He aquí las justificaciones"
				}
			</div>
			<div class="text-left">
				<div class="mt-3">
					<span class="bold">Pregunta 1:</span> todas las
					afirmaciones son correctas. Cabe destacar que la NL se
					caracteriza por la aparición de proteinuria en grado
					variable (40-100 %)1, alteraciones en el sedimento
					urinario (hematuria hasta en el 80 %), deterioro de la
					función renal (40-80%) y en algunos casos, desarrollo de
					hipertensión arterial (15-50 %)<sup>1</sup>. Además, el control
					adecuado de tensión arterial y de la dislipemia,
					frecuente en los pacientes con NL, son importantes para
					prevenir eventos cardiovasculares<sup>1</sup>.
				</div>
				<div class="mt-2">
					<span class="bold">Pregunta 2:</span> voclosporina ha
					demostrado ser eficaz en nefritis lúpica de nuevo
					diagnóstico y en recaídas y en varios perfiles de
					pacientes, incluidos pacientes con NL de
					clase III<sup>2-4</sup>.
				</div>
				<div class="mt-2">
					<span class="bold">Pregunta 3:</span> la afirmación es
					verdadera: la preservación de la función renal a largo
					plazo, junto con los resultados de seguridad favorables
					del estudio Aurora 2, establecen <span class="bold">un perfil
					beneficio-riesgo positivo para voclosporina como parte
					del tratamiento estándar de NL durante 3 años<sup>3</sup></span>.
				</div>
				<div class="txt9 mt-4">
					<sup>1</sup> 1 Rojas-Rivera JE, Praga Terente M. Nefropatía
					Lúpica. Lupus Eritematoso Sistémico. En: Lorenzo V, López Gómez
					JM (eds). Nefrología al día. Disponible en:
					https://www.nefrologiaaldia.org/589 [última consulta: 5 marzo
					2024].<br/>
					<sup>2</sup> Rovin BH, Onno Teng YK, Ginzler EM, et al. Efficacy and safety of voclosporin versus placebo for lupus nephritis (AURORA 1): a double-blind, randomised, multicentre, placebo-controlled, phase 3 trial. Lancet. 2021;397:2070-80. <br/>
					<sup>3</sup> Saxena A, Ginzler EM, Gibson K, et al. Safety and efficacy of long-Term voclosporin treatment for lupus nephritis in the phase 3 AURORA 2 clinical trial. Arthritis Rheumatol. 2024;76(1):59-67.<br/>
					<sup>4</sup> Arriens C, Onno Teng YK, Ginzler EM, et al. Update on the efficacy and safety profile of voclosporin: an integrated analysis of clinical trials in lupus nephritis. Arthritis Care Res (Hoboken). 2023;75(7):1399-1408.<br/>
					<strong>NL:</strong> nefritis lúpica
				</div>
			</div>
			</div>`;

			modalGeneric({ html: text }).then((res) => {
				if (res.isConfirmed) submitAnswer({ correct });
			});
		} else setCurrentQuestionNum(currentQuestionNum + 1);
	}

	async function submitAnswer({ correct }) {
		setIsCompleted(true);

		const score = correct ? CORRECT_ANSWER_POINTS : 0;

		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: questionOptions[currentQuestionNum]?.quizId,
			group: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateScore(score, SCORE_TYPES.SCORE));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						lastVisited: NAVIGATE_TO,
					});
					navigate(NAVIGATE_TO);
				}
			})
			.catch((error) => {});
	}

	return (
		<div className="h-100 bg-red">
			<div className="container">
				<div className="row h-100">
					<div className="row d-flex justify-content-center align-items-start mt-4">
						<div className="col-2">
							<img width={70} src={logoSupertest} />
						</div>
						<div className="col-8">
							<div className="d-flex justify-content-center txtWhite txt20 bold mb-4">
								{questionOptions[currentQuestionNum]?.title}
							</div>
							{questionOptions[currentQuestionNum]?.options?.map(
								(option) => {
									return (
										<div>
											<SelectOption id={option.id} />
										</div>
									);
								}
							)}
							<br />
							<div>
								<strong>NL:</strong> nefritis lúpica;{" "}
								<strong>LES:</strong> lupus eritematoso
								sistémico
							</div>
						</div>
						<div className="col-2"></div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					right: "4vh",
					bottom: "10vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					{!isCompleted && (
						<div
							className="btn-main-cancel-white"
							onClick={() => checkAnswers()}
						>
							<div className="text-btn-main">AVANZAR</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Supertest);
