import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "./App";
import Context from "./pages/Context";
import End from "./pages/End";
import Gameover from "./pages/Gameover";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Scenario1 from "./pages/scenario1/Scenario1";
import Scenario1Conversation from "./pages/scenario1/Scenario1Conversation";
import Scenario1ConversationIntro from "./pages/scenario1/Scenario1ConversationIntro";
import Scenario1Intro from "./pages/scenario1/Scenario1Intro";
import Scenario2 from "./pages/scenario2/Scenario2";
import Scenario2Intro from "./pages/scenario2/Scenario2Intro";
import Scenario2Result from "./pages/scenario2/Scenario2Result";
import Scenario3 from "./pages/scenario3/Scenario3";
import Scenario3Explanation from "./pages/scenario3/Scenario3Explanation";
import Scenario3Intro from "./pages/scenario3/Scenario3Intro";
import Scenario3Result from "./pages/scenario3/Scenario3Result";
import Scenario4 from "./pages/scenario4/Scenario4";
import Scenario4Intro from "./pages/scenario4/Scenario4Intro";
import Scenario5 from "./pages/scenario5/Scenario5";
import Scenario5Conversation from "./pages/scenario5/Scenario5Conversation";
import Scenario5End from "./pages/scenario5/Scenario5End";
import Scenario5Explanation from "./pages/scenario5/Scenario5Explanation";
import Scenario5Intro from "./pages/scenario5/Scenario5Intro";
import Scenario5Result from "./pages/scenario5/Scenario5Result";
import Scenario5Part2 from "./pages/scenario5Part2/Scenario5Part2";
import Scenario5Part2End from "./pages/scenario5Part2/Scenario5Part2End";
import Scenario5Part2Intro from "./pages/scenario5Part2/Scenario5Part2Intro";
import Scenario5Part2Intro2 from "./pages/scenario5Part2/Scenario5Part2Intro2";
import Scenario5Part2Question2 from "./pages/scenario5Part2/Scenario5Part2Question2";
import Scenario6 from "./pages/scenario6/Scenario6";
import Scenario6End from "./pages/scenario6/Scenario6End";
import Scenario6Intro from "./pages/scenario6/Scenario6Intro";
import Scenario7 from "./pages/scenario7/Scenario7";
import Scenario7End from "./pages/scenario7/Scenario7End";
import Scenario7Intro from "./pages/scenario7/Scenario7Intro";
import Scenario7Question2 from "./pages/scenario7/Scenario7Question2";
import Scenario7Question3 from "./pages/scenario7/Scenario7Question3";
import Supertest from "./pages/supertest/Supertest";
import SupertestEnd from "./pages/supertest/SupertestEnd";
import SupertestIntro from "./pages/supertest/SupertestIntro";
import TransitionScenarios from "./pages/TransitionScenarios";
import Video from "./pages/Video";
import { ROUTES } from "./utils/constants";

export default function Rutas() {
	const isRegistered = useSelector((state) => state.group, shallowEqual);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route path="/" element={<Home />} />
					<Route path={ROUTES.REGISTER} element={<Register />} />

					<Route path={ROUTES.VIDEO} element={<Video />} />
					<Route path={ROUTES.CONTEXT} element={<Context />} />
					<Route
						path={ROUTES.TRANSITION_SCENARIOS}
						element={<TransitionScenarios />}
					/>

					<Route
						path={ROUTES.SCENARIO1_INTRO}
						element={<Scenario1Intro />}
					/>
					<Route
						path={ROUTES.SCENARIO1_CONVERSATION_INTRO}
						element={<Scenario1ConversationIntro />}
					/>
					<Route
						path={ROUTES.SCENARIO1_CONVERSATION}
						element={<Scenario1Conversation />}
					/>
					<Route path={ROUTES.SCENARIO1} element={<Scenario1 />} />

					<Route
						path={ROUTES.SCENARIO2_INTRO}
						element={<Scenario2Intro />}
					/>
					<Route path={ROUTES.SCENARIO2} element={<Scenario2 />} />
					<Route
						path={ROUTES.SCENARIO2_RESULT}
						element={<Scenario2Result />}
					/>

					<Route
						path={ROUTES.SCENARIO3_INTRO}
						element={<Scenario3Intro />}
					/>
					<Route path={ROUTES.SCENARIO3} element={<Scenario3 />} />
					<Route
						path={ROUTES.SCENARIO3_EXPLANATION}
						element={<Scenario3Explanation />}
					/>
					<Route
						path={ROUTES.SCENARIO3_RESULT}
						element={<Scenario3Result />}
					/>

					<Route
						path={ROUTES.SCENARIO4_INTRO}
						element={<Scenario4Intro />}
					/>
					<Route path={ROUTES.SCENARIO4} element={<Scenario4 />} />

					<Route
						path={ROUTES.SCENARIO5_INTRO}
						element={<Scenario5Intro />}
					/>
					<Route
						path={ROUTES.SCENARIO5_CONVERSATION}
						element={<Scenario5Conversation />}
					/>
					<Route
						path={ROUTES.SCENARIO5_EXPLANATION}
						element={<Scenario5Explanation />}
					/>
					<Route path={ROUTES.SCENARIO5} element={<Scenario5 />} />
					<Route
						path={ROUTES.SCENARIO5_RESULT}
						element={<Scenario5Result />}
					/>
					<Route
						path={ROUTES.SCENARIO5_END}
						element={<Scenario5End />}
					/>

					<Route
						path={ROUTES.SCENARIO5_PART2_INTRO}
						element={<Scenario5Part2Intro />}
					/>
					<Route
						path={ROUTES.SCENARIO5_PART2_INTRO2}
						element={<Scenario5Part2Intro2 />}
					/>
					<Route
						path={ROUTES.SCENARIO5_PART2}
						element={<Scenario5Part2 />}
					/>
					<Route
						path={ROUTES.SCENARIO5_PART2_END}
						element={<Scenario5Part2End />}
					/>
					<Route
						path={ROUTES.SCENARIO5_PART2_QUESTION2}
						element={<Scenario5Part2Question2 />}
					/>

					<Route
						path={ROUTES.SCENARIO6_INTRO}
						element={<Scenario6Intro />}
					/>
					<Route path={ROUTES.SCENARIO6} element={<Scenario6 />} />
					<Route
						path={ROUTES.SCENARIO6_END}
						element={<Scenario6End />}
					/>

					<Route
						path={ROUTES.SCENARIO7_INTRO}
						element={<Scenario7Intro />}
					/>
					<Route path={ROUTES.SCENARIO7} element={<Scenario7 />} />
					<Route
						path={ROUTES.SCENARIO7_QUESTION2}
						element={<Scenario7Question2 />}
					/>
					<Route
						path={ROUTES.SCENARIO7_QUESTION3}
						element={<Scenario7Question3 />}
					/>
					<Route
						path={ROUTES.SCENARIO7_END}
						element={<Scenario7End />}
					/>

					<Route
						path={ROUTES.SUPERTEST_INTRO}
						element={<SupertestIntro />}
					/>
					<Route path={ROUTES.SUPERTEST} element={<Supertest />} />
					<Route
						path={ROUTES.SUPERTEST_END}
						element={<SupertestEnd />}
					/>

					<Route path={ROUTES.END} element={<End />} />

					<Route path={ROUTES.GAMEOVER} element={<Gameover />} />

					{!isRegistered ? (
						/*Render register page when not registered.*/
						<Route path="*" element={<Navigate to="/" replace />} />
					) : (
						<Route></Route>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	);
}
