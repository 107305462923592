import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMedical from "../../assets/logo-medical.png";
import martina from "../../assets/martina.png";
import NextButton from "../../components/NextButton";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function Scenario5End({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const scenarioNum = getScenarioByScreen(location.pathname);

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5">
					<div className="col-9">
						<div className="d-flex flex-column align-items-center">
							<img width={90} src={martina} />
							<div
								className="txt30 bold txt-yellow-light mt-4 text-center"
								style={{ lineHeight: "40px" }}
							>
								Parece evidente que hay que modificar
								<br /> el tratamiento a Martina.
							</div>
							<div className="text-center txt-white mt-3 px-5">
								De hecho, aunque a Martina se le ha prescrito un
								tratamiento con doble terapia, teniendo en
								cuenta sus datos y las recomendaciones de las
								guías,{" "}
								<span className="bold">
									se podría haber iniciado un tratamiento de
									primera línea directamente con triple
									terapia
								</span>
								. Veamos el algoritmo que propone el documento
								de consenso GLOSEN 2023<sup>1</sup>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton
						navigateTo={ROUTES.SCENARIO5_PART2_INTRO}
						activeScenario={scenarioNum + 1}
					/>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario5End);
