import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMedical from "../../assets/logo-medical.png";
import esquema from "../../assets/scenario5-esquema3.png";

import NextButton from "../../components/NextButton";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";

function Scenario5Part2Intro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					style={{ paddingLeft: "6vh" }}
					className="row d-flex justify-content-start align-items-start pt-5"
				>
					<div className="col-12">
						<img width={"90%"} src={esquema} />
					</div>
					<div
						className="container"
						style={{
							position: "absolute",
							right: "7vh",
							bottom: "7vh",
						}}
					>
						<div className="d-flex justify-content-end">
							<NextButton
								navigateTo={ROUTES.SCENARIO5_PART2_INTRO2}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario5Part2Intro);
