import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import informe from "../../assets/informe.png";
import logoMedical from "../../assets/logo-medical.png";

import NextButton from "../../components/NextButton";
import { useLang } from "../../translations/i18n";
import { ROUTES } from "../../utils/constants";

function Scenario2Intro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div
					style={{ paddingLeft: "6vh" }}
					className="row d-flex justify-content-start align-items-start mt-5 pt-5"
				>
					<div className="col-4 txtWhite">
						<div
							className="txtWhite txt20"
							style={{ letterSpacing: 3 }}
						>
							ESCENARIO 2
						</div>
						<div
							className="txtWhite txt30 bold mt-3"
							style={{ letterSpacing: 3 }}
						>
							Diagnóstico
						</div>
						<div className="txt16 bold mt-5 w-75">
							¡Han aparecido los resultados de la biopsia renal! 
						</div>
						<div className="mt-2">Este es el informe:</div>
					</div>
					<div
						className="d-flex justify-content-end"
						style={{
							position: "absolute",
							right: "7%",
							bottom: "0%",
						}}
					>
						<div className="col-8">
							<img height={580} src={informe} />
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "4vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton navigateTo={ROUTES.SCENARIO2} />
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario2Intro);
